import React from "react";

const RenderCircle = (filled: boolean) => {
  return (
    <span
      style={{
        display: "inline-block",
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        background: filled ? "green" : "transparent",
        border: "2px solid green",
        marginRight: "5px",
      }}
    ></span>
  );
};
export default RenderCircle;
