import HomeService from "./homeService";
import providerService from "./providerService";
import customerService from "./customerService";
import authService from "./authService";
import adminService from "./adminService";

const Services = {
  home: HomeService,
  provider: providerService,
  customer: customerService,
  auth: authService,
  admin: adminService,
};

export default Services;
