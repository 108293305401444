import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Input from "../../../../component/input";
import serviceNames from "../../service-provider/services/serviceData";
import { StyledProvider } from "./style";
import React, { useEffect, useState } from "react";
import SnowRemovalPriceList from "./price-list/snow-removal";
import { useFormik } from "formik";
import * as Yup from "yup";
import HouseCleanPriceList from "./price-list/house-clean";
import InHomeSalonMenKidsPriceList from "./price-list/inhome-salon-men-kids";
import InHomeSalonWomenPriceList from "./price-list/inhome-salon-women";
import DrivingInstructors66PriceList from "./price-list/driving-instructors-6-6";
import DrivingInstructorsHourlyPriceList from "./price-list/driving-instructors-hourly";
import AcademicHelpersPriceList from "./price-list/academic-helpers";
import Services from "../../../../api/services";
import Swal from "sweetalert2";
// import LawnCare from "./price-list/lawn-care";
import MobileCarDetailing from "./price-list/mobile-car-detailing";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface formDataProps {
  service: string;
  profile: any;
  id?: number;
  serviceArea: string;
  businessYear: string;
  standardDescription: string;
  standardPrice: string;
  //Snow Removal
  noOfCarsDriveWay?: string[];
  noOfCrDriveWayPrice?: string[];
  blackIcePrice?: string;
  cornerHousePrice?: string;
  snowSizePrice?: string;
  //Mobile car detailing
  typeOfCar?: string[];
  typeOfCarPrice?: string[];
  leatherSeatsPrice?: string;
  leatherInteriorPrice?: string;
  //House Clean
  noOfBedroom?: string[];
  noOfBdrmPrice?: string[];
  noOfbathroom?: string[];
  noOfbathrmPrice?: string[];
  cabinets?: string;
  fridge?: string;
  stoveOven?: string;
  windowsHoor?: string;
  carpet?: string;
  // InHomeSalonMenKids
  hairCutPrice?: string;
  beardPrice?: string;
  hairCutBeardPrice?: string;
  // InHomeSalonWomen
  regularHairCutPrice?: string;
  facialPrice?: string;
  threading?: string[];
  threadingPrice?: string[];
  waxing?: string[];
  waxingPrice?: string[];
  eyebrows?: string[];
  eyebrowsPrice?: string[];
  eyeLashes?: string[];
  eyeLashesPrice?: string[];
  // driving-instructors-hourly
  drivingHourlyLessonCharges: string;
  // driving-instructors-6/6
  drivingCertificatePrice: string;
  //academic-helpers
  academicHourlySessionCharge: string;
  subjects?: any;
  academicConcern?: boolean;
}

interface AddServiceProps {
  setServiceData: any;
  editService?: boolean;
  serviceData: any;
  setAddService: (value: boolean) => void;
}

const AddService: React.FC<AddServiceProps> = ({
  setServiceData,
  setAddService,
  editService = false,
  serviceData,
}) => {
  const [serviceWilling, setServiceWilling] = useState(
    "Standard service description"
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [priceListId, setPriceListId] = useState(0);

  const formik = useFormik<formDataProps>({
    initialValues: {
      id: serviceData[0]?.id || 0,
      service: serviceData[0]?.service || "",
      profile: serviceData[0]?.profile || null,
      serviceArea: serviceData[0]?.service_area || "",
      businessYear: serviceData[0]?.business_year || "",
      standardDescription: serviceData[0]?.standard_description || "",
      standardPrice: serviceData[0]?.standard_price || "",
      //House Clean
      noOfCarsDriveWay: serviceData[0]?.no_of_cars_drive_way_first
        ? serviceData[0]?.no_of_cars_drive_way_first.split(",")
        : [""],
      noOfCrDriveWayPrice: serviceData[0]?.no_of_cars_drive_way_price_first
        ? serviceData[0]?.no_of_cars_drive_way_price_first.split(",")
        : [""],
      blackIcePrice: serviceData[0]?.blackIce_price || "",
      cornerHousePrice: serviceData[0]?.corner_house_price || "",
      snowSizePrice: serviceData[0]?.snow_size_price || "",
      //Mobile car detailling
      typeOfCar: serviceData[0]?.type_car
        ? serviceData[0]?.type_car.split(",")
        : [""],
      typeOfCarPrice: serviceData[0]?.type_car_price
        ? serviceData[0]?.type_car_price.split(",")
        : [""],
      leatherSeatsPrice: serviceData[0]?.leather_seats_price || "",
      leatherInteriorPrice: serviceData[0]?.leather_interior_price || "",
      //House Clean
      noOfBedroom: serviceData[0]?.no_of_bedroom
        ? serviceData[0]?.no_of_bedroom.split(",")
        : [""],
      noOfBdrmPrice: serviceData[0]?.no_of_bedroom_price
        ? serviceData[0]?.no_of_bedroom_price.split(",")
        : [""],
      noOfbathroom: serviceData[0]?.no_of_bathroom
        ? serviceData[0]?.no_of_bathroom.split(",")
        : [""],
      noOfbathrmPrice: serviceData[0]?.no_of_bathroom_price
        ? serviceData[0]?.no_of_bathroom_price.split(",")
        : [""],
      cabinets: serviceData[0]?.cabinets || "",
      fridge: serviceData[0]?.fridge || "",
      stoveOven: serviceData[0]?.stove_oven || "",
      windowsHoor: serviceData[0]?.windows_hoor || "",
      carpet: serviceData[0]?.carpet || "",
      // InHomeSalonMenKids
      hairCutPrice: serviceData[0]?.hair_cut_price || "",
      beardPrice: serviceData[0]?.beard_price || "",
      hairCutBeardPrice: serviceData[0]?.hair_cut_beard_price || "",
      // InHomeSalonWomen
      regularHairCutPrice: serviceData[0]?.regular_hair_cut_price || "",
      facialPrice: serviceData[0]?.facial_price || "",
      threading: serviceData[0]?.threading
        ? serviceData[0]?.threading.split(",")
        : [""],
      threadingPrice: serviceData[0]?.threading_price
        ? serviceData[0]?.threading_price.split(",")
        : [""],
      waxing: serviceData[0]?.waxing ? serviceData[0]?.waxing.split(",") : [""],
      waxingPrice: serviceData[0]?.waxing_price
        ? serviceData[0]?.waxing_price.split(",")
        : [""],
      eyebrows: serviceData[0]?.eye_brows
        ? serviceData[0]?.eye_brows.split(",")
        : [""],
      eyebrowsPrice: serviceData[0]?.eye_brows_price
        ? serviceData[0]?.eye_brows_price.split(",")
        : [""],
      eyeLashes: serviceData[0]?.eye_lashes
        ? serviceData[0]?.eye_lashes.split(",")
        : [""],
      eyeLashesPrice: serviceData[0]?.eye_lashes_price
        ? serviceData[0]?.eye_lashes_price.split(",")
        : [""],
      academicConcern: serviceData[0]?.academic_concern || false,
      // driving-instructors-hourly
      drivingHourlyLessonCharges:
        serviceData[0]?.driving_hourly_lesson_charges || "",
      // driving-instructors-6/6
      drivingCertificatePrice: serviceData[0]?.driving_certificate_price || "",
      //academic-helpers
      academicHourlySessionCharge:
        serviceData[0]?.academic_hourly_session_charge || "",
      subjects: serviceData[0]?.subjects
        ? serviceData[0]?.subjects.split(",")
        : [""],
    },
    validationSchema: Yup.object({
      service: Yup.string().required("Service is required"),
      // profile: Yup.string().required("Profile is required"),
      profile: Yup.mixed().required("Profile image is required"),
      serviceArea: Yup.string().required("Service area is required"),
      businessYear: Yup.string().required("Business year is required"),
      standardDescription: Yup.string().required(
        "Standard description is required"
      ),
      standardPrice: Yup.string().required("Standard price is required"),
      ...(priceListId === 1 && {
        noOfCarsDriveWay: Yup.array()
          .min(1, "Field must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        noOfCrDriveWayPrice: Yup.array()
          .min(1, "Field must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),

        blackIcePrice: Yup.string().required("Black Ice price is required"),
        cornerHousePrice: Yup.string().required(
          "Corner house price is required"
        ),
        snowSizePrice: Yup.string().required(
          "Snow more than 8 inches price is required"
        ),
      }),
      ...(priceListId === 2 && {
        noOfBedroom: Yup.array()
          .min(1, "No of bedroom must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        noOfBdrmPrice: Yup.array()
          .min(1, "No of bedroom price must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        noOfbathroom: Yup.array()
          .min(1, "No of bathroom must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        noOfbathrmPrice: Yup.array()
          .min(1, "No of bathroom price must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),

        cabinets: Yup.string().required("Cabinets price is required"),
        fridge: Yup.string().required("Fridge price is required"),
        stoveOven: Yup.string().required("Stove/Oven price is required"),
        windowsHoor: Yup.string().required("Windows/Hoor price is required"),
        carpet: Yup.string().required("Carpet price is required"),
      }),
      ...(priceListId === 3 && {
        hairCutPrice: Yup.string().required("hair cut price is required"),
        beardPrice: Yup.string().required("beard price is required"),
        hairCutBeardPrice: Yup.string().required(
          "hair cut beard price is required"
        ),
      }),
      ...(priceListId === 4 && {
        regularHairCutPrice: Yup.string().required(
          "Regular hair cut price is required"
        ),
        facialPrice: Yup.string().required("Facial price is required"),
        threading: Yup.array()
          .min(1, "Threading must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        threadingPrice: Yup.array()
          .min(1, "Threading price must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        waxing: Yup.array()
          .min(1, "Waxing must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        waxingPrice: Yup.array()
          .min(1, "Waxing price must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        eyebrows: Yup.array()
          .min(1, "Eyebrows must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        eyebrowsPrice: Yup.array()
          .min(1, "eyebrows price must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        eyeLashes: Yup.array()
          .min(1, "EyeLashes must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        eyeLashesPrice: Yup.array()
          .min(1, "EyeLashes price must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
      }),
      ...(priceListId === 6 && {
        drivingHourlyLessonCharges: Yup.string().required(
          "Hourly lesson charges is required"
        ),
      }),
      ...(priceListId === 5 && {
        drivingCertificatePrice: Yup.string().required(
          "6/6 with certificate is required"
        ),
      }),
      ...((priceListId === 7 || priceListId === 8) && {
        academicHourlySessionCharge: Yup.string().required(
          "Hourly session charge is required"
        ),
        subjects: Yup.array().min(1, "Please select at least one subject"),
        academicConcern: Yup.boolean().oneOf(
          [true],
          "You must agree to the terms"
        ),
      }),
      ...((priceListId === 10 || priceListId === 11) && {
        typeOfCar: Yup.array()
          .min(1, "Field must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),
        typeOfCarPrice: Yup.array()
          .min(1, "Field must have at least one element")
          .test(
            "is-not-empty-string",
            "First element cannot be an empty string",
            (value) => {
              if (!value || value.length < 1) return false;
              return value[0] && value[0] !== "";
            }
          )
          .of(Yup.string()),

        leatherSeatsPrice: Yup.string().required(
          "Leather seats price is required"
        ),
        leatherInteriorPrice: Yup.string().required(
          "Leather interior price is required"
        ),
      }),
    }),
    onSubmit: async (values) => {
      console.log(values);

      setIsSubmitted(true);
      // Handle form submission
      var bodyFormData = new FormData();
      bodyFormData.append("service", values.service);
      bodyFormData.append("profile", values.profile);
      bodyFormData.append("service_area", values.serviceArea);
      bodyFormData.append("business_year", values.businessYear);
      bodyFormData.append("standard_description", values.standardDescription);
      bodyFormData.append("standard_price", values.standardPrice);

      bodyFormData.append(
        "no_of_cars_drive_way_first",
        values.noOfCarsDriveWay ? values.noOfCarsDriveWay.join(",") : ""
      );

      bodyFormData.append(
        "no_of_cars_drive_way_price_first",
        values.noOfCrDriveWayPrice ? values.noOfCrDriveWayPrice.join(",") : ""
      );
      bodyFormData.append(
        "blackIce_price",
        values.blackIcePrice ? values.blackIcePrice : ""
      );
      bodyFormData.append(
        "corner_house_price",
        values.cornerHousePrice ? values.cornerHousePrice : ""
      );
      bodyFormData.append(
        "snow_size_price",
        values.snowSizePrice ? values.snowSizePrice : ""
      );
      bodyFormData.append(
        "no_of_bedroom",
        values.noOfBedroom ? values.noOfBedroom.join(",") : ""
      );
      bodyFormData.append(
        "no_of_bedroom_price",
        values.noOfBdrmPrice ? values.noOfBdrmPrice.join(",") : ""
      );
      bodyFormData.append(
        "no_of_bathroom",
        values.noOfbathroom ? values.noOfbathroom.join(",") : ""
      );
      bodyFormData.append(
        "no_of_bathroom_price",
        values.noOfbathrmPrice ? values.noOfbathrmPrice.join(",") : ""
      );
      bodyFormData.append("cabinets", values.cabinets ? values.cabinets : "");
      bodyFormData.append("fridge", values.fridge ? values.fridge : "");
      bodyFormData.append(
        "stove_oven",
        values.stoveOven ? values.stoveOven : ""
      );
      bodyFormData.append(
        "windows_hoor",
        values.windowsHoor ? values.windowsHoor : ""
      );
      bodyFormData.append("carpet", values.carpet ? values.carpet : "");
      bodyFormData.append(
        "hair_cut_price",
        values.hairCutPrice ? values.hairCutPrice : ""
      );
      bodyFormData.append(
        "beard_price",
        values.beardPrice ? values.beardPrice : ""
      );
      bodyFormData.append(
        "hair_cut_beard_price",
        values.hairCutBeardPrice ? values.hairCutBeardPrice : ""
      );
      bodyFormData.append(
        "regular_hair_cut_price",
        values.regularHairCutPrice ? values.regularHairCutPrice : ""
      );
      bodyFormData.append(
        "facial_price",
        values.facialPrice ? values.facialPrice : ""
      );
      bodyFormData.append(
        "threading",
        values.threading ? values.threading.join(",") : ""
      );
      bodyFormData.append(
        "threading_price",
        values.threadingPrice ? values.threadingPrice.join(",") : ""
      );
      bodyFormData.append(
        "waxing",
        values.waxing ? values.waxing.join(",") : ""
      );
      bodyFormData.append(
        "waxing_price",
        values.waxingPrice ? values.waxingPrice.join(",") : ""
      );
      bodyFormData.append(
        "eye_brows",
        values.eyebrows ? values.eyebrows.join(",") : ""
      );
      bodyFormData.append(
        "eye_brows_price",
        values.eyebrowsPrice ? values.eyebrowsPrice.join(",") : ""
      );
      bodyFormData.append(
        "eye_lashes",
        values.eyeLashes ? values.eyeLashes.join(",") : ""
      );
      bodyFormData.append(
        "eye_lashes_price",
        values.eyeLashesPrice ? values.eyeLashesPrice.join(",") : ""
      );

      bodyFormData.append(
        "academic_concern",
        values.academicConcern ? "true" : "false"
      );

      bodyFormData.append(
        "driving_hourly_lesson_charges",
        values.drivingHourlyLessonCharges
      );
      bodyFormData.append(
        "driving_certificate_price",
        values.drivingCertificatePrice
      );
      bodyFormData.append(
        "academic_hourly_session_charge",
        values.academicHourlySessionCharge
      );
      bodyFormData.append("subjects", values.subjects);
      bodyFormData.append(
        "type_car",
        values.typeOfCar ? values.typeOfCar.join(",") : ""
      );

      bodyFormData.append(
        "type_car_price",
        values.typeOfCarPrice ? values.typeOfCarPrice.join(",") : ""
      );
      bodyFormData.append(
        "leather_seats_price",
        values.leatherSeatsPrice ? values.leatherSeatsPrice : ""
      );
      bodyFormData.append(
        "leather_interior_price",
        values.leatherInteriorPrice ? values.leatherInteriorPrice : ""
      );

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = (await serviceData[0]?.id)
            ? Services.provider.updateServiceDataById(
                serviceData[0]?.id,
                bodyFormData,
                options
              )
            : Services.provider.addServiceData(bodyFormData, options);

          if ((await response).status === 200) {
            const responseData = await Services.provider.getServicesData(
              options
            );
            setServiceData(responseData.data.data);
            Swal.fire({
              title: serviceData[0]?.id ? "Updated!" : "Added!",
              text: serviceData[0]?.id
                ? "Updated service successfully."
                : "Add service successfully.",
              icon: "success",
            });
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
      setAddService(false);
    },
  });

  const handleInputChange = (event: any) => {
    const { name, value, files } = event.target;

    if (files && files.length > 0) {
      // Handle file selection
      const selectedFile = files[0];
      formik.setFieldValue(name, selectedFile);
    } else {
      formik.setFieldValue(name, value);
    }

    if (name === "service") {
      const serviceResult = getStandardServiceDesc(value);
      if (serviceResult) {
        setServiceWilling(serviceResult.standardServiceDesc);
        setPriceListId(serviceResult.id);
      }
    }
  };

  const getStandardServiceDesc = (serviceName: string) => {
    const service = serviceNames.find(
      (service) => service.name === serviceName
    );
    return service;
  };

  useEffect(() => {
    // Extracting the complex expression into a separate variable
    const firstService = serviceData[0]?.service;

    // Include the variable in the dependency array
    if (firstService) {
      const serviceResult = getStandardServiceDesc(firstService);
      if (serviceResult) {
        setServiceWilling(serviceResult.standardServiceDesc);
        setPriceListId(serviceResult.id);
      }
    }
  }, [serviceData]);

  const combinedChangeHandler = (e: any) => {
    formik.handleChange(e);
    handleInputChange(e);
  };

  const subjectsList = [
    {
      id: 1,
      name: "Maths",
    },
    {
      id: 2,
      name: "English",
    },
    {
      id: 3,
      name: "Science",
    },
    {
      id: 4,
      name: "Accounts",
    },
    {
      id: 5,
      name: "Maths(Grade 12 & above)",
    },
    {
      id: 6,
      name: "All Subjects(Grade 1-10)",
    },
  ];

  return (
    <Box sx={{ pb: 2 }} component="form" onSubmit={formik.handleSubmit}>
      <StyledProvider>
        <FormGroup>
          <FormLabel>Service Willing to offer</FormLabel>
          <Select
            name="service"
            value={formik.values.service}
            onChange={combinedChangeHandler}
            displayEmpty
            defaultValue=""
          >
            <MenuItem value="" disabled>
              Please select service
            </MenuItem>
            {serviceNames &&
              serviceNames.map((item) => (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          {formik.touched.service && formik.errors.service && (
            <Typography className="error">{formik.errors.service}</Typography>
          )}
        </FormGroup>

        {(priceListId === 7 || priceListId === 8) && (
          <FormGroup>
            <FormLabel>Subject of interest</FormLabel>
            <Select
              name="subjects"
              value={formik.values.subjects}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              displayEmpty
              defaultValue=""
              onChange={formik.handleChange}
              renderValue={(selected) =>
                Array.isArray(selected) ? selected.join(", ") : ""
              }
              MenuProps={MenuProps}
              sx={{
                "&.MuiInputBase-root": {
                  border: "1px solid #ccc",
                  backgroundColor: "#FFF",
                  width: "100%",
                  height: "48px",
                  "@media (max-width: 992px)": {
                    width: "100%",
                    height: "48px",
                  },
                },
                fieldset: {
                  display: "none",
                },
              }}
            >
              <MenuItem value={""} disabled>
                Please Select
              </MenuItem>
              {subjectsList.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  <Checkbox
                    checked={formik.values.subjects.indexOf(item.name) > -1}
                  />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
            {formik.touched.subjects && formik.errors.subjects && (
              <Typography className="error">
                {formik.errors.subjects as React.ReactNode}
              </Typography>
            )}
          </FormGroup>
        )}

        <FormGroup>
          <FormLabel>Profile Picture To Display</FormLabel>
          <Input type="file" name="profile" onChange={handleInputChange} />
          {formik.touched.profile && formik.errors.profile && (
            <Typography className="error">
              {formik.errors.profile.toString()}
            </Typography>
          )}
          {serviceData[0]?.profile && (
            <Box
              sx={{
                width: "150px",
                borderRadius: "5px",
                padding: "5px",
                border: "1px solid #ccc",
                mt: 2,
                position: "relative",
              }}
            >
              <img
                src={`${process.env.REACT_APP_BACKEND_BASE_URL}upload/${serviceData[0]?.profile}`}
                alt="Preview"
                style={{ maxWidth: "100%", display: "inherit" }}
              />
            </Box>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Service Area</FormLabel>
          <Input
            type="text"
            name="serviceArea"
            placeholder="Service Area"
            value={formik.values.serviceArea}
            onChange={formik.handleChange}
          />
          {formik.touched.serviceArea && formik.errors.serviceArea && (
            <Typography className="error">
              {formik.errors.serviceArea}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Year In Business</FormLabel>
          <Input
            type="number"
            name="businessYear"
            placeholder="Year In Business"
            value={formik.values.businessYear}
            onChange={formik.handleChange}
          />
          {formik.touched.businessYear && formik.errors.businessYear && (
            <Typography className="error">
              {formik.errors.businessYear}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Standard service description to display</FormLabel>
          <textarea
            className="form-control"
            name="standardDescription"
            placeholder={serviceWilling}
            rows={4}
            style={{ height: "100px", resize: "none" }}
            value={formik.values.standardDescription}
            onChange={formik.handleChange}
          ></textarea>
          {formik.touched.standardDescription &&
            formik.errors.standardDescription && (
              <Typography className="error">
                {formik.errors.standardDescription}
              </Typography>
            )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Standard service price</FormLabel>
          <Input
            type="number"
            name="standardPrice"
            placeholder="Standard service price"
            value={formik.values.standardPrice}
            onChange={formik.handleChange}
          />
          {formik.touched.standardPrice && formik.errors.standardPrice && (
            <Typography className="error">
              {formik.errors.standardPrice}
            </Typography>
          )}
        </FormGroup>

        {(priceListId === 7 || priceListId === 8) && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="academicConcern"
                  checked={formik.values.academicConcern}
                  onChange={formik.handleChange}
                />
              }
              label="You agree to arrange online session link / study material by yourself"
            />
            {formik.touched.academicConcern &&
              formik.errors.academicConcern && (
                <Typography className="error">
                  {formik.errors.academicConcern}
                </Typography>
              )}
          </FormGroup>
        )}

        {priceListId !== 0 && (
          <>
            {priceListId !== 9 && (
              <Typography variant="h5" sx={{ fontWeight: 700, mb: 1 }}>
                Price List
              </Typography>
            )}

            {priceListId === 1 && <SnowRemovalPriceList formik={formik} />}

            {priceListId === 2 && <HouseCleanPriceList formik={formik} />}

            {priceListId === 3 && (
              <InHomeSalonMenKidsPriceList formik={formik} />
            )}

            {priceListId === 4 && <InHomeSalonWomenPriceList formik={formik} />}

            {priceListId === 5 && (
              <DrivingInstructors66PriceList formik={formik} />
            )}

            {priceListId === 6 && (
              <DrivingInstructorsHourlyPriceList formik={formik} />
            )}

            {(priceListId === 7 || priceListId === 8) && (
              <AcademicHelpersPriceList formik={formik} />
            )}

            {/* {priceListId === 9 && <LawnCare formik={formik} />} */}

            {(priceListId === 10 || priceListId === 11) && (
              <MobileCarDetailing formik={formik} />
            )}
          </>
        )}

        <Button type="submit" variant="contained" disabled={isSubmitted}>
          {isSubmitted ? (
            <span style={{ color: "#fff" }}>Submitting...</span>
          ) : editService ? (
            "Update Service"
          ) : (
            "Add Service"
          )}
          {/* Submit for review */}
        </Button>
      </StyledProvider>
    </Box>
  );
};

export default AddService;
