const AdminDictionary = {
  getProviderList: () => "/serviceprovider/get_provider_list/",
  updateUserStatus: () => "/user/update_user_status",
  updateServiceStatus: () => "/serviceprovider/update_service_status",
  getAllServices: () => "/serviceprovider/get_all_services_data",
  getAllBookings: () => "/serviceprovider/get_all_bookings/",
  viewBookings: (bookingId: number) =>
    `/serviceprovider/get_booked_service/${bookingId}`,
  updateBookings: (bookingId: number) =>
    `/serviceprovider/update_booked_service/${bookingId}`,
  getBanner: () => "/serviceprovider/get_banner",
  viewBanner: (bannerId: number) => `/serviceprovider/view_banner/${bannerId}`,
  updateBanner: () => "/serviceprovider/update_banner",
  deleteBanner: (bannerId: number) =>
    `/serviceprovider/delete_banner/${bannerId}`,
  addBanner: () => "/serviceprovider/add_banner",
  getRefundAndCredit: () => "/serviceprovider/get_refund_and_credit/",
};

export default AdminDictionary;
