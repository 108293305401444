export const SidebarMenu = [
  {
    id: 1,
    url: "/dashboard/my-profile",
    label: "My Profile",
  },
  {
    id: 2,
    url: "/dashboard/my-services",
    label: "My services",
  },
  {
    id: 3,
    url: "/dashboard/refund-and-credits",
    label: "Refund & credits",
  },
  {
    id: 4,
    url: "/dashboard/payment-method",
    label: "Payment method",
  },
  {
    id: 5,
    url: "/dashboard/change-password",
    label: "Change password",
  },
  {
    id: 6,
    url: "/become-helping-hand",
    label: "Become Helping Hand",
  },
  {
    id: 7,
    url: "/dashboard/logout",
    label: "Log out",
  },
];

export const ProviderSidebarMenu = [
  {
    id: 2,
    url: "/dashboard/profile",
    label: "Profile",
  },
  {
    id: 3,
    url: "/dashboard/banking-details",
    label: "Banking Details",
  },
  {
    id: 4,
    url: "/dashboard/availability",
    label: "Availability",
  },
  {
    id: 5,
    url: "/dashboard/services",
    label: "My Services",
  },
  {
    id: 6,
    url: "/dashboard/earnings",
    label: "Earnings",
  },
  {
    id: 7,
    url: "/dashboard/documents",
    label: "Documents",
  },
  {
    id: 8,
    url: "/dashboard/service-history",
    label: "Service History",
  },
  {
    id: 9,
    url: "/dashboard/reviews",
    label: "Reviews",
  },
  {
    id: 10,
    url: "/dashboard/logout",
    label: "Log out",
  },
];

export const AdminSidebarMenu = [
  {
    id: 1,
    url: "/dashboard/all-services",
    label: "All Services",
  },

  {
    id: 2,
    url: "/dashboard/bookings",
    label: "All Bookings",
  },

  {
    id: 3,
    url: "/dashboard/provider-list",
    label: "Service Provider",
  },

  {
    id: 4,
    url: "/dashboard/banner",
    label: "Banner",
  },

  {
    id: 5,
    url: "/dashboard/all-refund-credit",
    label: "Refund & Credit",
  },

  {
    id: 6,
    url: "/services",
    label: "Create Order",
  },

  {
    id: 7,
    url: "/dashboard/logout",
    label: "Log out",
  },
];
