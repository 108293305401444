import { Box, Dialog, IconButton, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface DrawerProps {
  isOpen: boolean;
  handleClose?: () => void;
  title?: string;
  children?: any;
}
const Drawer: React.FC<DrawerProps> = ({
  children,
  isOpen = false,
  handleClose,
  title,
}) => {
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <Box sx={{ p: 3, position: "relative" }}>
          {handleClose && (
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: "15px", right: "15px" }}
            >
              <HighlightOffIcon />
            </IconButton>
          )}

          <Typography variant="h4">{title}</Typography>

          {children}
        </Box>
      </Dialog>
    </>
  );
};
export default Drawer;
