import { Box } from "@mui/system";
import { Link, useLocation } from "react-router-dom";
import { SidebarMenu, ProviderSidebarMenu, AdminSidebarMenu } from "./menuData";
import { useSelector } from "react-redux";

interface SidebarProps {
  handleDrawerClose: () => void;
}
const Sidebar: React.FC<SidebarProps> = ({ handleDrawerClose }) => {
  const userType = useSelector((state: any) => state.userData.user_type);
  const location = useLocation();

  return (
    <>
      <Box
        component={"ul"}
        sx={{
          margin: "0px",
          padding: "0px",
          "& li": {
            listStyle: "none",
            "& a": {
              textDecoration: "none",
              display: "block",
              color: "#6c757d",
              fontFamily: "'Poppins',sans-serif",
              padding: "7px 10px",
              "&:hover": { background: "#e0f3ff", color: "#3f6ad8" },
            },
          },
        }}
      >
        {userType &&
          (userType === "consumer"
            ? SidebarMenu
            : userType === "super_admin"
            ? AdminSidebarMenu
            : ProviderSidebarMenu
          ).map((item: any, index: number) => (
            <li key={item.id}>
              <Link
                onClick={handleDrawerClose}
                style={{
                  color: location.pathname.includes(item.url)
                    ? "#3f6ad8"
                    : "#6c757d",
                  background: location.pathname.includes(item.url)
                    ? "#e0f3ff"
                    : "#fff",
                }}
                to={item.url}
              >
                {item.label}
              </Link>
            </li>
          ))}
      </Box>
    </>
  );
};
export default Sidebar;
