import dictionary from "../dictionary";
import requestModule from "../methods";

const { auth } = dictionary;
const { post, put } = requestModule;

const AuthService = {
  loginService: (body: {}) => {
    return post(auth.login(), body);
  },
  forgotPasswordService: (body: {}) => {
    return post(auth.forgotPassword(), body);
  },
  updateUserService: (body: {}, options: {}) => {
    return put(auth.updateUserDetails(), body, options);
  },
  changePasswordService: (body: {}, options: {}) => {
    return put(auth.changePassword(), body, options);
  },
  resetPasswordService: (body: {}) => {
    return post(auth.resetPassword(), body);
  },
};

export default AuthService;
