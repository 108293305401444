import { Typography } from "@mui/material";
import ListingTable from "../component/ListingTable";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Services from "../../../api/services";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../store";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../store/reducers";

interface Column {
  id: "serviceName" | "providerName" | "date" | "amount" | "status";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [
  { id: "serviceName", label: "Service Name", minWidth: 170 },
  { id: "providerName", label: "Provider Name", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 170 },
  { id: "amount", label: "Amount", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 170 },
];

interface Data {
  id: number;
  serviceName: string;
  providerName: string;
  date: string;
  amount: string;
  status: React.ReactNode;
}

function createData(
  id: number,
  serviceName: string,
  providerName: string,
  date: string,
  amount: string,
  status: React.ReactNode
): Data {
  return { id, serviceName, providerName, date, amount, status };
}

const RefundAndCredits = () => {
  const dispatch = useDispatch();
  // const totalWalletAmount = useSelector(
  //   (state: RootState) => state.userData.user_wallet
  // );
  const [refundData, setRefundData] = useState([{}]);
  const [availableCredit, setAvailableCredit] = useState(0);
  const getRefundData = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.customer.getMyRefundData(options);
        setRefundData(response.data.data);
        const refundCredit = response.data.data.filter(
          (item: any) =>
            item.refund_status === "1" &&
            item.refund_type === "Helping Hand Credit"
        );

        const totalRefundCredit = refundCredit.reduce(
          (total: any, item: any) => total + parseFloat(item.total_amount),
          0
        );

        if (totalRefundCredit > 0) {
          var bodyFormData = new FormData();
          bodyFormData.append("user_wallet", totalRefundCredit);

          const result = await Services.auth.updateUserService(
            bodyFormData,
            options
          );
          dispatch(setUserData(result.data.userData));
          const credit =
            totalRefundCredit - result.data.userData.used_wallet_amount;
          setAvailableCredit(credit > 0 ? credit : 0);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getRefundData();
  }, []);

  const rows = refundData.map((item: any, index: number) => {
    const rowData = {
      id: index,
      serviceName: item.service_name,
      providerName: item.name,
      serviceDate: item.service_date_time,
      serviceAmount: item.total_amount,
      refundStatus: item.refund_status,
    };

    return createData(
      rowData.id,
      rowData.serviceName,
      rowData.providerName,
      rowData.serviceDate,
      `$${rowData.serviceAmount}`,
      <Box component="span" sx={{ color: "green" }}>
        {rowData.refundStatus !== "0" ? "Paid" : "unpaid"}
      </Box>
    );
  });

  return (
    <>
      <Typography
        sx={{
          mb: 2,
          fontSize: "24px",
          "@media (max-width:992px)": { fontSize: "18px" },
        }}
        variant="h3"
      >
        Your Available Credit: ${availableCredit.toFixed(2)}
      </Typography>
      <Box>
        <ListingTable columns={columns} rows={rows} />
      </Box>
    </>
  );
};

export default RefundAndCredits;
