import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setUserData,
  setAccessToken,
  // setWalletAmount,
} from "../../store/reducers";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("access_token");
    dispatch(setUserData({}));
    dispatch(setAccessToken(""));
    // dispatch(setWalletAmount(0));
    navigate("/");

    // Empty dependency array ensures this runs only once on mount
  }, [dispatch, navigate]);

  return null;
};

export default Logout;
