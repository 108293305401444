import { Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Input from "../../../../../component/input";

interface AcademicHelpersOnlineProps {
  formik: any;
}

const AcademicHelpers: React.FC<AcademicHelpersOnlineProps> = ({ formik }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Description</b>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Price</b>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Hourly session charge</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="academicHourlySessionCharge"
                value={formik.values.academicHourlySessionCharge}
                onChange={formik.handleChange}
              />
              {formik.touched.academicHourlySessionCharge &&
                formik.errors.academicHourlySessionCharge && (
                  <Typography className="error">
                    {formik.errors.academicHourlySessionCharge}
                  </Typography>
                )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AcademicHelpers;
