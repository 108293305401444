import {
  Button,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Input from "../../../../../component/input";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Services from "../../../../../api/services";
import Swal from "sweetalert2";
import { StyledProvider } from "../../service/style";

interface formDataProps {
  id?: number;
  serviceName: string;
  serviceProvider: string;
  consumerName: string;
  consumerPhone: string;
  consumerEmail: string;
  consumerAddress: string;
  serviceDateTime: string;
  standardPrice: string;
  gst: string;
  pst: string;
  tip: string;
  status: string;
  serviceCharge: string;
  adjustmentAmount: string;
  totalAmount: string;
  rejectReason: string;
  issueReason: string;
  cancelReason: string;
  refundType: string;
  refundStatus: string;
}

interface AddServiceProps {
  setBookingsData: any;
  bookingsData: any;
  setEditService: any;
}

const EditBooking: React.FC<AddServiceProps> = ({
  setBookingsData,
  bookingsData,
  setEditService,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formik = useFormik<formDataProps>({
    initialValues: {
      id: bookingsData[0]?.id || 0,
      serviceName: bookingsData[0]?.service_name || "",
      standardPrice: bookingsData[0]?.service_amount || "",
      serviceProvider: bookingsData[0]?.service_provider || "",
      consumerName: bookingsData[0]?.name || "",
      consumerPhone: bookingsData[0]?.phone_number || "",
      consumerEmail: bookingsData[0]?.email || "",
      consumerAddress: bookingsData[0]?.address || "",
      serviceDateTime: bookingsData[0]?.service_date_time || "",
      gst: bookingsData[0]?.gst_tax || "",
      pst: bookingsData[0]?.pst_tax || "",
      tip: bookingsData[0]?.tip || "",
      status: bookingsData[0]?.status || "",
      serviceCharge: bookingsData[0]?.service_charge || "",
      adjustmentAmount: bookingsData[0]?.adjustment_amount || "",
      totalAmount: bookingsData[0]?.total_amount || "",
      rejectReason: bookingsData[0]?.reject_reason || "",
      issueReason: bookingsData[0]?.issue_reason || "",
      cancelReason: bookingsData[0]?.cancel_reason || "",
      refundType: bookingsData[0]?.refund_type || "",
      refundStatus: bookingsData[0]?.refund_status || "",
    },
    validationSchema: Yup.object({
      serviceName: Yup.string().required("Service area is required"),
      standardPrice: Yup.string().required("Standard price is required"),
      serviceProvider: Yup.string().required("Service provider is required"),
      consumerName: Yup.string().required("Consumer name is required"),

      consumerPhone: Yup.string().required("Consumer phone is required"),
      consumerEmail: Yup.string().required("Consumer email is required"),
      consumerAddress: Yup.string().required("Consumer address is required"),
      serviceDateTime: Yup.string().required("Service datetime is required"),

      gst: Yup.string().required("GST is required"),
      pst: Yup.string().required("PST is required"),
      tip: Yup.string().required("TIP is required"),
      status: Yup.string().required("Status is required"),
      serviceCharge: Yup.string().required("Service charge is required"),

      adjustmentAmount: Yup.string().required("Adjustment amount is required"),
      totalAmount: Yup.string().required("Total amount is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitted(true);
      var bodyFormData = {
        id: values.id,
        adjustment_amount: values.adjustmentAmount,
        total_amount: values.totalAmount,
        refund_status: values.refundStatus,
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = Services.admin.updateBookingService(
            bookingsData[0]?.id,
            bodyFormData,
            options
          );

          if ((await response).status === 200) {
            const responseData = await Services.admin.getRefundAndCreditService(
              options
            );
            setBookingsData(responseData.data.data);
            Swal.fire({
              title: "Updated!",
              text: "Updated booking successfully.",
              icon: "success",
            });
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
      setEditService(false);
    },
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    const totalAmt = (
      parseFloat(bookingsData[0]?.total_amount) - parseFloat(value)
    ).toFixed(2);
    formik.setFieldValue(name, value);
    formik.setFieldValue("totalAmount", totalAmt);
  };

  return (
    <Box sx={{ pb: 2 }} component="form" onSubmit={formik.handleSubmit}>
      <StyledProvider>
        <FormGroup>
          <FormLabel>Service Name</FormLabel>
          <Input
            type="text"
            name="serviceName"
            placeholder="Service Name"
            readOnly={true}
            value={formik.values.serviceName}
            onChange={formik.handleChange}
          />
          {formik.touched.serviceName && formik.errors.serviceName && (
            <Typography className="error">
              {formik.errors.serviceName}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Service Provider</FormLabel>
          <Input
            type="text"
            name="serviceProvider"
            placeholder="Service Provider"
            readOnly={true}
            value={formik.values.serviceProvider}
            onChange={formik.handleChange}
          />
          {formik.touched.serviceProvider && formik.errors.serviceProvider && (
            <Typography className="error">
              {formik.errors.serviceProvider}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Customer Name</FormLabel>
          <Input
            type="text"
            name="consumerName"
            placeholder="Customer Name"
            readOnly={true}
            value={formik.values.consumerName}
            onChange={formik.handleChange}
          />
          {formik.touched.consumerName && formik.errors.consumerName && (
            <Typography className="error">
              {formik.errors.consumerName}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Customer Phone</FormLabel>
          <Input
            type="text"
            name="consumerPhone"
            placeholder="Customer Phone"
            readOnly={true}
            value={formik.values.consumerPhone}
            onChange={formik.handleChange}
          />
          {formik.touched.consumerPhone && formik.errors.consumerPhone && (
            <Typography className="error">
              {formik.errors.consumerPhone}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Customer Email</FormLabel>
          <Input
            type="text"
            name="consumerEmail"
            placeholder="Customer Email"
            readOnly={true}
            value={formik.values.consumerEmail}
            onChange={formik.handleChange}
          />
          {formik.touched.consumerEmail && formik.errors.consumerEmail && (
            <Typography className="error">
              {formik.errors.consumerEmail}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Customer Address</FormLabel>
          <Input
            type="text"
            name="consumerAddress"
            placeholder="Customer Address"
            readOnly={true}
            value={formik.values.consumerAddress}
            onChange={formik.handleChange}
          />
          {formik.touched.consumerAddress && formik.errors.consumerAddress && (
            <Typography className="error">
              {formik.errors.consumerAddress}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Service DateTime</FormLabel>
          <Input
            type="text"
            name="serviceDateTime"
            placeholder="Service DateTime"
            readOnly={true}
            value={formik.values.serviceDateTime}
            onChange={formik.handleChange}
          />
          {formik.touched.serviceDateTime && formik.errors.serviceDateTime && (
            <Typography className="error">
              {formik.errors.serviceDateTime}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Standard service price</FormLabel>
          <Input
            type="number"
            readOnly={true}
            name="standardPrice"
            placeholder="Standard service price"
            value={formik.values.standardPrice}
            onChange={formik.handleChange}
          />
          {formik.touched.standardPrice && formik.errors.standardPrice && (
            <Typography className="error">
              {formik.errors.standardPrice}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Service Charge</FormLabel>
          <Input
            type="number"
            readOnly={true}
            name="serviceCharge"
            placeholder="Service Charge"
            value={formik.values.serviceCharge}
            onChange={formik.handleChange}
          />
          {formik.touched.serviceCharge && formik.errors.serviceCharge && (
            <Typography className="error">
              {formik.errors.serviceCharge}
            </Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>GST</FormLabel>
          <Input
            type="number"
            readOnly={true}
            name="gst"
            placeholder="gst"
            value={formik.values.gst}
            onChange={formik.handleChange}
          />
          {formik.touched.gst && formik.errors.gst && (
            <Typography className="error">{formik.errors.gst}</Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>PST</FormLabel>
          <Input
            type="number"
            readOnly={true}
            name="pst"
            placeholder="pst"
            value={formik.values.pst}
            onChange={formik.handleChange}
          />
          {formik.touched.pst && formik.errors.pst && (
            <Typography className="error">{formik.errors.pst}</Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>TIP</FormLabel>
          <Input
            type="number"
            readOnly={true}
            name="tip"
            placeholder="tip"
            value={formik.values.tip}
            onChange={formik.handleChange}
          />
          {formik.touched.tip && formik.errors.tip && (
            <Typography className="error">{formik.errors.tip}</Typography>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Adjustment Amount</FormLabel>
          <Input
            type="number"
            name="adjustmentAmount"
            placeholder="Adjustment Amount"
            value={formik.values.adjustmentAmount}
            onChange={handleInputChange}
          />
          {formik.touched.adjustmentAmount &&
            formik.errors.adjustmentAmount && (
              <Typography className="error">
                {formik.errors.adjustmentAmount}
              </Typography>
            )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Total Amount</FormLabel>
          <Input
            type="number"
            readOnly={true}
            name="totalAmount"
            placeholder="total Amount"
            value={formik.values.totalAmount}
            onChange={formik.handleChange}
          />
          {formik.touched.totalAmount && formik.errors.totalAmount && (
            <Typography className="error">
              {formik.errors.totalAmount}
            </Typography>
          )}
        </FormGroup>

        {formik.values.status === "rejected" && (
          <FormGroup>
            <FormLabel>Reject Reason</FormLabel>
            <Input
              type="text"
              readOnly={true}
              name="rejectReason"
              placeholder="Reject Reason"
              value={formik.values.rejectReason}
              onChange={formik.handleChange}
            />
            {formik.touched.rejectReason && formik.errors.rejectReason && (
              <Typography className="error">
                {formik.errors.rejectReason}
              </Typography>
            )}
          </FormGroup>
        )}

        {formik.values.status === "issue" && (
          <FormGroup>
            <FormLabel>Issue Reason</FormLabel>
            <Input
              type="text"
              readOnly={true}
              name="issueReason"
              placeholder="Issue Reason"
              value={formik.values.issueReason}
              onChange={formik.handleChange}
            />
            {formik.touched.issueReason && formik.errors.issueReason && (
              <Typography className="error">
                {formik.errors.issueReason}
              </Typography>
            )}
          </FormGroup>
        )}

        {formik.values.status === "cancelled" && (
          <>
            <FormGroup>
              <FormLabel>Cancel Reason</FormLabel>
              <Input
                type="text"
                readOnly={true}
                name="cancelReason"
                placeholder="Cancel Reason"
                value={formik.values.cancelReason}
                onChange={formik.handleChange}
              />
              {formik.touched.cancelReason && formik.errors.cancelReason && (
                <Typography className="error">
                  {formik.errors.cancelReason}
                </Typography>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel>Refund Type</FormLabel>
              <Select
                disabled={true}
                name="refundType"
                value={formik.values.refundType}
                onChange={formik.handleChange}
                displayEmpty
                defaultValue=""
              >
                <MenuItem value="" disabled>
                  Please select
                </MenuItem>
                <MenuItem value="Helping Hand Credit">
                  Helping Hand Credit
                </MenuItem>
                <MenuItem value="Refund">Refund</MenuItem>
              </Select>
              {formik.touched.refundType && formik.errors.refundType && (
                <Typography className="error">
                  {formik.errors.refundType}
                </Typography>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel>Refund Status</FormLabel>
              <Select
                name="refundStatus"
                value={formik.values.refundStatus}
                onChange={formik.handleChange}
                displayEmpty
                defaultValue=""
              >
                <MenuItem value="" disabled>
                  Please select
                </MenuItem>
                <MenuItem value="0">Pending</MenuItem>
                <MenuItem value="1">Done</MenuItem>
                <MenuItem value="2">Reject</MenuItem>
              </Select>
              {formik.touched.refundStatus && formik.errors.refundStatus && (
                <Typography className="error">
                  {formik.errors.refundStatus}
                </Typography>
              )}
            </FormGroup>
          </>
        )}

        <FormGroup>
          <FormLabel>Booking Status</FormLabel>
          <Select
            name="status"
            disabled={true}
            value={formik.values.status}
            onChange={formik.handleChange}
            displayEmpty
            defaultValue=""
          >
            <MenuItem value="" disabled>
              Please select service
            </MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="accepted">Accepted</MenuItem>
            <MenuItem value="rejected">Rejected</MenuItem>
            <MenuItem value="issue">Issue</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </Select>
          {formik.touched.status && formik.errors.status && (
            <Typography className="error">
              {formik.errors.status as React.ReactNode}
            </Typography>
          )}
        </FormGroup>

        <Button type="submit" variant="contained" disabled={isSubmitted}>
          {isSubmitted ? (
            <span style={{ color: "#fff" }}>Submitting...</span>
          ) : (
            "Update"
          )}
        </Button>
      </StyledProvider>
    </Box>
  );
};

export default EditBooking;
