import { Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Input from "../../../../../component/input";

interface InHomeSalonMenKidsProps {
  formik: any;
}

const InHomeSalonMenKids: React.FC<InHomeSalonMenKidsProps> = ({ formik }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Description</b>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Price</b>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Hair Cut</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="hairCutPrice"
                value={formik.values.hairCutPrice}
                onChange={formik.handleChange}
              />
              {formik.touched.hairCutPrice && formik.errors.hairCutPrice && (
                <Typography className="error">
                  {formik.errors.hairCutPrice}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Beard</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="beardPrice"
                value={formik.values.beardPrice}
                onChange={formik.handleChange}
              />
              {formik.touched.beardPrice && formik.errors.beardPrice && (
                <Typography className="error">
                  {formik.errors.beardPrice}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Hair Cut & Beard</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="hairCutBeardPrice"
                value={formik.values.hairCutBeardPrice}
                onChange={formik.handleChange}
              />
              {formik.touched.hairCutBeardPrice &&
                formik.errors.hairCutBeardPrice && (
                  <Typography className="error">
                    {formik.errors.hairCutBeardPrice}
                  </Typography>
                )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InHomeSalonMenKids;
