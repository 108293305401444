import { Button, Card, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Input from "../../../component/input";
import { useFormik } from "formik";
import * as Yup from "yup";
import Services from "../../../api/services";
import Swal from "sweetalert2";

interface AddPaymentProps {
  setAddPayment: (value: boolean) => void;
  editPayment?: boolean;
  cardData: any;
  setCardData: any;
}

const AddPayment: React.FC<AddPaymentProps> = ({
  setAddPayment,
  editPayment = false,
  cardData,
  setCardData,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const luhnCheck = (value: string) => {
    let sum = 0;
    let shouldDouble = false;
    for (let i = value.length - 1; i >= 0; i--) {
      let digit = parseInt(value.charAt(i), 10);
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
  };

  const formik = useFormik({
    initialValues: {
      name: cardData[0]?.card_holder_name || "",
      cardNumber: cardData[0]?.card_number || "",
      expiryYear: cardData[0]?.expiry_year || "",
      expiryMonth: cardData[0]?.expiry_month || "",
      cvv: cardData[0]?.cvv || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, "Name can only contain letters and spaces")
        .min(1, "Name is required")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      cardNumber: Yup.string()
        .matches(/^\d{13,19}$/, "Card number must be between 13 and 19 digits")
        .test("luhn", "Invalid card number", (value) => luhnCheck(value || ""))
        .required("Card number is required"),
      expiryMonth: Yup.number()
        .min(1, "Invalid month")
        .max(12, "Invalid month")
        .required("Expiry month is required"),
      expiryYear: Yup.number()
        .test("isValidYear", "Invalid year", (value) => {
          const currentYear = new Date().getFullYear();
          return (
            (value ?? 0) >= currentYear && (value ?? 0) <= currentYear + 10
          );
        })
        .required("Expiry year is required"),
      cvv: Yup.string()
        .matches(/^\d{3,4}$/, "CVV must be 3 or 4 digits")
        .required("CVV is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitted(true);
      // Handle form submission

      const data = {
        card_holder_name: values.name,
        card_number: values.cardNumber,
        expiry_month: values.expiryMonth,
        expiry_year: values.expiryYear,
        cvv: values.cvv,
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = (await cardData[0]?.id)
            ? Services.customer.updatePaymentMethodService(
                cardData[0]?.id,
                data,
                options
              )
            : Services.customer.addPaymentMethodService(data, options);

          if ((await response).status === 200) {
            const responseData =
              await Services.customer.getAllPaymentMethodsService(options);
            setCardData(responseData.data.payment_methods);
            Swal.fire({
              title: cardData[0]?.id ? "Updated!" : "Added!",
              text: cardData[0]?.id
                ? "Updated payment method successfully."
                : "Add payment method successfully.",
              icon: "success",
            });
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }

      // navigate("/dashboard/payment-method");
      setAddPayment(false);
    },
  });

  return (
    <Box sx={{ mt: 5 }} component={"form"} onSubmit={formik.handleSubmit}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} lg={7}>
          <Box
            component={Card}
            elevation={3}
            sx={{ p: 3, borderRadius: "8px" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Typography variant={"h6"}>Name on Card:</Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Input
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name &&
                  typeof formik.errors.name === "string" && (
                    <Typography className="error">
                      {formik.errors.name}
                    </Typography>
                  )}
                {/* {formik.touched.name && formik.errors.name && (
                  <Typography className="error">
                    {formik.errors.name}
                  </Typography>
                )} */}
              </Grid>

              <Grid item xs={12} lg={4}>
                <Typography variant={"h6"}>Card Number:</Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Input
                  name="cardNumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cardNumber}
                />
                {formik.touched.cardNumber &&
                  typeof formik.errors.cardNumber === "string" && (
                    <Typography className="error">
                      {formik.errors.cardNumber}
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12} lg={4}>
                <Typography variant={"h6"}>Expiry Date:</Typography>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Input
                  name="expiryMonth"
                  type="number"
                  onChange={(e: any) => {
                    if (e.target.value.length <= 2 && e.target.value <= 12) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.expiryMonth}
                />
                {formik.touched.expiryMonth &&
                  typeof formik.errors.expiryMonth === "string" && (
                    <Typography className="error">
                      {formik.errors.expiryMonth}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} lg={4}>
                <Input
                  name="expiryYear"
                  type="number"
                  onChange={formik.handleChange}
                  // onChange={(e: any) => {
                  //   const year = parseInt(e.target.value, 10);
                  //   if (validateYear(year)) {
                  //     formik.handleChange(e);
                  //   }
                  // }}
                  onBlur={formik.handleBlur}
                  value={formik.values.expiryYear}
                />
                {formik.touched.expiryYear &&
                  typeof formik.errors.expiryYear === "string" && (
                    <Typography className="error">
                      {formik.errors.expiryYear}
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12} lg={4}>
                <Typography variant={"h6"}>CVV:</Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Input
                  name="cvv"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvv}
                />
                {formik.touched.cvv &&
                  typeof formik.errors.cvv === "string" && (
                    <Typography className="error">
                      {formik.errors.cvv}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} lg={12} sx={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  disabled={isSubmitted}
                  variant="contained"
                  size="large"
                >
                  {isSubmitted ? (
                    <span style={{ color: "#fff" }}>Submitting...</span>
                  ) : editPayment ? (
                    "Update Card"
                  ) : (
                    "Add Card"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AddPayment;
