import { Button, FormGroup, FormLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import Input from "../../../../component/input";
import Services from "../../../../api/services";
import Swal from "sweetalert2";

interface sinNumberProps {
  sinNumber: string;
}

interface sinProps {
  handleClose: (value: boolean) => void;
}

const SinNumber: React.FC<sinProps> = ({ handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<sinNumberProps>>({});
  const [formData, setFormData] = useState<sinNumberProps>({
    sinNumber: "",
  });

  const validateForm = () => {
    const newErrors: Partial<sinNumberProps> = {};

    if (!formData.sinNumber.trim()) {
      newErrors.sinNumber = "SIN Number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);

      var data = new FormData();
      data.append("sin_number", formData.sinNumber);

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateSINNumberService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "SIN number has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup sx={{ my: 2 }}>
          <FormLabel sx={{ mb: 1 }}>SIN Number</FormLabel>
          <Input
            type="number"
            name="sinNumber"
            value={formData.sinNumber}
            onChange={handleInputChange}
          />
          {errors.sinNumber && (
            <Typography color="error">{errors.sinNumber}</Typography>
          )}
        </FormGroup>
        <Button type="submit" variant="contained" disabled={isSubmitted}>
          {isSubmitted ? (
            <span style={{ color: "#fff" }}>Submitting...</span>
          ) : (
            "Submit"
          )}
        </Button>
      </form>
    </>
  );
};

export default SinNumber;
