import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  userData: Record<string, any>;
  accessToken: string | null;
  // walletAmount: number;
}

const initialState: UserState = {
  userData: {},
  accessToken: null,
  // walletAmount: 0,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<Record<string, any>>) => {
      state.userData = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    // setWalletAmount: (state, action: PayloadAction<number>) => {
    //   state.walletAmount = action.payload;
    // },
  },
});

export const {
  setUserData,
  setAccessToken,
  //setWalletAmount
} = userSlice.actions;
export default userSlice.reducer;
