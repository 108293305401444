import Logo from "./logo.png";
import Slider1 from "./slider1.png";
import SnowRemowal from "./icon_snow_removal.png";
import HouseCleaning from "./icon_house_cleaning.png";
import Packers from "./icon_packers_and_movers.png";
import HomeSalon from "./icon_salon_services.png";
import Driving from "./icon_driving_instrutors.png";
import category1 from "./cleaning.jpg";
import category2 from "./ice_removal.jpg";
import category3 from "./hair_styling.jpg";
import category4 from "./kenny-eliason-tVv667jkTUc-unsplash.jpg";
import Team1 from "./team_1.png";
import Team2 from "./team_2.png";
import Team3 from "./team_3.png";
import PhoneBg from "./phone-bg.jpg";
import Phone from "./phone_icon.png";
import makepoll from "./work-bg.jpg";
import aboutImg from "./AboutUs.jpg";
import innerHeadingImg from "./innerHeading.jpg";
import SnowRemoval from "./Residential-Snow-Clearing.png";
import User from "./user.png";
import ServiceHistory from "./service-history.png";
import Rating from "./rating.png";
import Earnings from "./earnings.png";
import Documentation from "./documentation.png";
import CustomerService from "./customer-service.png";
import Availability from "./availability.png";
import callus from "./phone_icon2.png";
import Academic from "./academic.png";
import LawnCare from "./lawnCare.jpg";
import CarDetailing from "./carDetailing.jpg";
import ComingSoon from "./coming-soon.png";
import CustomerAgreement from "./customer-agreement.pdf";

export const IMAGES = {
  Logo,
  User,
  Slider1,
  SnowRemowal,
  HouseCleaning,
  Packers,
  HomeSalon,
  Driving,
  category1,
  category2,
  category3,
  category4,
  Team1,
  Team2,
  Team3,
  Phone,
  Rating,
  PhoneBg,
  makepoll,
  Earnings,
  aboutImg,
  SnowRemoval,
  Availability,
  Documentation,
  ServiceHistory,
  CustomerService,
  innerHeadingImg,
  callus,
  Academic,
  LawnCare,
  CarDetailing,
  ComingSoon,
  CustomerAgreement,
};
