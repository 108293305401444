export default function Radio() {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.MuiRadio-root": {
            "&.Mui-checked": {
              color: "#11b4f5",
            },
          },
        },
      },
    },
  };
}
