import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import Input from "../../../component/input";
import { Stack } from "@mui/system";
import { useState } from "react";

interface forgotPasswordProps {
  title: string;
}

interface formForgotPasswordProps {
  email: string;
}

const ForgotPassword: React.FC<forgotPasswordProps> = ({ title }) => {
  const [errors, setErrors] = useState<Partial<formForgotPasswordProps>>({});
  const [formData, setFormData] = useState<formForgotPasswordProps>({
    email: "",
  });

  const validateForm = () => {
    const newErrors: Partial<formForgotPasswordProps> = {};
    // Validate email (required)
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form data submitted:", formData);
    } else {
      console.log("Form validation failed:", errors);
    }
  };
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          {title}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>Email Address</FormLabel>
                <Input
                  name="email"
                  placeholder="Email Address"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <Typography color="error">{errors.email}</Typography>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button variant="contained" type="submit">
                  Update
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default ForgotPassword;
