import { Stack } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";
import ListingTable from "../../../component/ListingTable";
import { useEffect, useState } from "react";
import Services from "../../../../../api/services";
import Drawer from "../../Drawer";
import ProviderEdit from "./providerEdit";

interface Column {
  id:
    | "providerName"
    | "serviceName"
    | "email"
    | "phoneNumber"
    | "legallyAuthorized"
    | "BackGroundStatus"
    | "UserStatus"
    | "action";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [
  { id: "providerName", label: "Provider Name", minWidth: 70 },
  { id: "serviceName", label: "Service Names", minWidth: 200 },
  { id: "email", label: "Email", minWidth: 150 },
  { id: "phoneNumber", label: "Phone number", minWidth: 150 },
  { id: "legallyAuthorized", label: "legally authorized", minWidth: 70 },
  { id: "BackGroundStatus", label: "Background status", minWidth: 50 },
  { id: "UserStatus", label: "User status", minWidth: 50 },
  { id: "action", label: "Action", minWidth: 120 },
];

interface Data {
  id: number;
  providerName: string;
  serviceName: string;
  email: string;
  phoneNumber: string;
  legallyAuthorized: string;
  BackGroundStatus: React.ReactNode;
  UserStatus: React.ReactNode;
  action: React.ReactNode;
}

function createData(
  id: number,
  providerName: string,
  serviceName: string,
  email: string,
  phoneNumber: string,
  legallyAuthorized: string,
  BackGroundStatus: React.ReactNode,
  UserStatus: React.ReactNode,
  action: React.ReactNode
): Data {
  return {
    id,
    providerName,
    serviceName,
    email,
    phoneNumber,
    legallyAuthorized,
    BackGroundStatus,
    UserStatus,
    action,
  };
}

interface UserData {
  id: number;
  userStatus: string;
  backgroundCheck: number;
}

const ProviderList = () => {
  const [providerData, setProviderData] = useState([{}]);
  const [userData, setUserData] = useState<UserData>({
    id: 0,
    userStatus: "",
    backgroundCheck: 1,
  });
  const [open, setOpen] = useState(false);

  const getProviderList = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.admin.getProviderListService(options);
        setProviderData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getProviderList();
  }, []);

  const handleStatus = async (
    id: number,
    userStatus: string,
    backgroundCheck: number
  ) => {
    setUserData({
      id,
      userStatus,
      backgroundCheck,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rows = providerData.map((item: any) => {
    let providerServices = "";
    if (item.services) {
      providerServices = Object.entries(JSON.parse(item.services))
        .filter(([key, value]) => value === true)
        .map(([key, value]) => key)
        .join(", ");
    }

    const rowData = {
      id: item.id,
      providerName: `${item.first_name} ${item.last_name}`,
      serviceName: providerServices,
      email: item.email,
      phoneNumber: `${item.phone_extension} ${item.phone_number}`,
      legallyAuthorized:
        item.legally_authorized_to_work_in_canada === 1 ? "Yes" : "No",
      backGroundStatus: item.allow_background_check,
      userStatus: item.user_status,
    };

    return createData(
      rowData.id,
      rowData.providerName,
      rowData.serviceName,
      rowData.email,
      rowData.phoneNumber,
      rowData.legallyAuthorized,
      rowData.backGroundStatus === 1 ? (
        <Box component="span" sx={{ color: "#11b4f5" }}>
          Pending
        </Box>
      ) : item.allow_background_check === 2 ? (
        <Box component="span" sx={{ color: "#008000" }}>
          Done
        </Box>
      ) : (
        <Box component="span" sx={{ color: "#FF0000" }}>
          Rejected
        </Box>
      ),
      <Box
        component="span"
        sx={{
          color:
            rowData.userStatus === "pending"
              ? "#11b4f5"
              : rowData.userStatus === "approve"
              ? "#008000"
              : "#FF0000",
        }}
      >
        {rowData.userStatus}
      </Box>,
      <Stack direction={"row"} spacing={1}>
        <Button
          variant="text"
          onClick={() =>
            handleStatus(
              rowData.id,
              rowData.userStatus,
              item.allow_background_check
            )
          }
        >
          Edit
        </Button>
      </Stack>
    );
  });

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent="space-between"
        display="flex"
        marginBottom={2}
        alignItems={"center"}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "24px",
            mb: 1,
            "@media(max-width: 768px)": {
              fontSize: "18px",
            },
          }}
        >
          All Service Providers
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            mb: 1,
            alignItems: "center",
            display: "flex",
          }}
        ></Typography>
      </Stack>
      <ListingTable columns={columns} rows={rows} shouldShowPagination={true} />
      <Drawer isOpen={open} handleClose={handleClose} title="Edit Provider">
        <ProviderEdit
          userData={userData}
          setProviderData={setProviderData}
          handleClose={handleClose}
        />
      </Drawer>
    </>
  );
};

export default ProviderList;
