import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Input from "../../../../../component/input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface HouseCleanProps {
  formik: any;
}

const HouseClean: React.FC<HouseCleanProps> = ({ formik }) => {
  const handleAddButton = (id: number) => {
    if (id === 1) {
      formik.setFieldValue("noOfBedroom", [...formik.values.noOfBedroom, ""]);
      formik.setFieldValue("noOfBdrmPrice", [
        ...formik.values.noOfBdrmPrice,
        "",
      ]);
    }

    if (id === 2) {
      formik.setFieldValue("noOfbathroom", [...formik.values.noOfbathroom, ""]);
      formik.setFieldValue("noOfbathrmPrice", [
        ...formik.values.noOfbathrmPrice,
        "",
      ]);
    }
  };

  const handleRemoveButton = (id: number, itemId: number) => {
    if (itemId === 1) {
      const newNoOfBedroom = [...formik.values.noOfBedroom];
      newNoOfBedroom.splice(id, 1);
      formik.setFieldValue("noOfBedroom", newNoOfBedroom);

      const newNoOfBdrmPrice = [...formik.values.noOfBdrmPrice];
      newNoOfBdrmPrice.splice(id, 1);
      formik.setFieldValue("noOfBdrmPrice", newNoOfBdrmPrice);
    }

    if (itemId === 2) {
      const newNoOfbathroom = [...formik.values.noOfbathroom];
      newNoOfbathroom.splice(id, 1);
      formik.setFieldValue("noOfbathroom", newNoOfbathroom);

      const newNoOfbathrmPrice = [...formik.values.noOfbathrmPrice];
      newNoOfbathrmPrice.splice(id, 1);
      formik.setFieldValue("noOfbathrmPrice", newNoOfbathrmPrice);
    }
  };

  const handleChange = (id: number, newValue: any, itemId: number) => {
    if (itemId === 1) {
      const newPrices = [...formik.values.noOfBdrmPrice];
      newPrices[id] = newValue;
      formik.setFieldValue("noOfBdrmPrice", newPrices);
    }

    if (itemId === 2) {
      const newPrices = [...formik.values.noOfbathrmPrice];
      newPrices[id] = newValue;
      formik.setFieldValue("noOfbathrmPrice", newPrices);
    }
  };

  const handleSelect = (id: number, newValue: any, itemId: number) => {
    if (itemId === 1) {
      const newNoOfBedroom = [...formik.values.noOfBedroom];
      newNoOfBedroom[id] = newValue;
      formik.setFieldValue("noOfBedroom", newNoOfBedroom);
    }

    if (itemId === 2) {
      const newNoOfBathroom = [...formik.values.noOfbathroom];
      newNoOfBathroom[id] = newValue;
      formik.setFieldValue("noOfbathroom", newNoOfBathroom);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Description</b>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Price</b>
        </Grid>

        {formik.values.noOfBedroom.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
              >
                <Typography component={"span"}>No of bedrooms</Typography>
                <Box>
                  <Select
                    size="small"
                    name="noOfBedroom"
                    value={formik.values.noOfBedroom[index]}
                    onChange={(e) => handleSelect(index, e.target.value, 1)}
                    sx={{ width: 150, height: "48px" }}
                    displayEmpty
                    defaultValue=""
                  >
                    <MenuItem disabled value="">
                      Please select
                    </MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                  </Select>
                  {formik.touched.noOfBedroom && formik.errors.noOfBedroom && (
                    <Typography className="error">
                      {formik.errors.noOfBedroom}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography component={"span"}>$</Typography>
                <Box>
                  <Input
                    type="text"
                    name="noOfBdrmPrice"
                    value={formik.values.noOfBdrmPrice[index]}
                    onChange={(e: any) =>
                      handleChange(index, e.target.value, 1)
                    }
                  />
                  {formik.touched.noOfBdrmPrice &&
                    formik.errors.noOfBdrmPrice && (
                      <Typography className="error">
                        {formik.errors.noOfBdrmPrice}
                      </Typography>
                    )}
                </Box>
                {index === 0 ? (
                  <IconButton
                    onClick={
                      formik.values.noOfBedroom.length < 4
                        ? () => handleAddButton(1)
                        : undefined
                    }
                    sx={{
                      backgroundColor: "#11b4f5",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleRemoveButton(index, 1)}
                    sx={{
                      backgroundColor: "red",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "red",
                        color: "#FFF",
                      },
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </React.Fragment>
        ))}

        {formik.values.noOfbathroom.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
              >
                <Typography component={"span"}>No of bathrooms</Typography>
                <Box>
                  <Select
                    size="small"
                    name="noOfbathroom"
                    value={formik.values.noOfbathroom[index]}
                    onChange={(e) => handleSelect(index, e.target.value, 2)}
                    sx={{ width: 150, height: "48px" }}
                    displayEmpty
                    defaultValue=""
                  >
                    <MenuItem disabled value="">
                      Please select
                    </MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                  </Select>
                  {formik.touched.noOfbathroom &&
                    formik.errors.noOfbathroom && (
                      <Typography className="error">
                        {formik.errors.noOfbathroom}
                      </Typography>
                    )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography component={"span"}>$</Typography>
                <Box>
                  <Input
                    type="text"
                    name="noOfbathrmPrice"
                    value={formik.values.noOfbathrmPrice[index]}
                    onChange={(e: any) =>
                      handleChange(index, e.target.value, 2)
                    }
                  />
                  {formik.touched.noOfbathrmPrice &&
                    formik.errors.noOfbathrmPrice && (
                      <Typography className="error">
                        {formik.errors.noOfbathrmPrice}
                      </Typography>
                    )}
                </Box>
                {index === 0 ? (
                  <IconButton
                    onClick={
                      formik.values.noOfbathroom.length < 4
                        ? () => handleAddButton(2)
                        : undefined
                    }
                    sx={{
                      backgroundColor: "#11b4f5",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleRemoveButton(index, 2)}
                    sx={{
                      backgroundColor: "red",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "red",
                        color: "#FFF",
                      },
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Deep clean cabinets</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="cabinets"
                value={formik.values.cabinets}
                onChange={formik.handleChange}
              />
              {formik.touched.cabinets && formik.errors.cabinets && (
                <Typography className="error">
                  {formik.errors.cabinets}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Deep clean fridge</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="fridge"
                value={formik.values.fridge}
                onChange={formik.handleChange}
              />
              {formik.touched.fridge && formik.errors.fridge && (
                <Typography className="error">
                  {formik.errors.fridge}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Deep clean stove & oven</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="stoveOven"
                value={formik.values.stoveOven}
                onChange={formik.handleChange}
              />
              {formik.touched.stoveOven && formik.errors.stoveOven && (
                <Typography className="error">
                  {formik.errors.stoveOven}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Deep clean windows & hoor</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="windowsHoor"
                value={formik.values.windowsHoor}
                onChange={formik.handleChange}
              />
              {formik.touched.windowsHoor && formik.errors.windowsHoor && (
                <Typography className="error">
                  {formik.errors.windowsHoor}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Deep clean carpet</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="carpet"
                value={formik.values.carpet}
                onChange={formik.handleChange}
              />
              {formik.touched.carpet && formik.errors.carpet && (
                <Typography className="error">
                  {formik.errors.carpet}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HouseClean;
