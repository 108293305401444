import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { IMAGES } from "../../assets/images";
import { MENU } from "./helper";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Header() {
  const userType = useSelector((state: any) => state.userData.user_type);
  const userName = useSelector((state: any) => state.userData.first_name);
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const settings = [
    {
      id: 1,
      name: "Dashboard",
      url: userType === "consumer" ? "/dashboard/my-profile" : "/dashboard",
    },
    { id: 2, name: "Logout", url: "/dashboard/logout" },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: "#fff",

        zIndex: 10,
        position: "relative",
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Typography
            variant="h1"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img alt="Logo" src={IMAGES.Logo} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {MENU.map((item) => (
                <MenuItem
                  component={Link}
                  to={item.url}
                  key={item.id}
                  onClick={handleCloseNavMenu}
                >
                  <Typography textAlign="center">{item.label}</Typography>
                </MenuItem>
              ))}

              {!userType && (
                <MenuItem
                  component={Link}
                  to={"/login"}
                  onClick={handleCloseNavMenu}
                >
                  LOGIN
                </MenuItem>
              )}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 500,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img alt="Logo" src={IMAGES.Logo} />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
            }}
          >
            {MENU.map((item) => (
              <Button
                component={Link}
                to={item.url}
                key={item.id}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: location.pathname.includes(item.url)
                    ? "green"
                    : "#000",
                  display: "block",
                }}
              >
                {item.label}
              </Button>
            ))}

            {!userType && (
              <Button
                sx={{ color: "#000", my: 2 }}
                component={Link}
                to={"/login"}
              >
                LOGIN
              </Button>
            )}
          </Box>

          {userType && (
            <>
              <Typography
                sx={{
                  color: "rgb(17, 180, 245)",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  "@media (max-width:600px)": {
                    display: "none",
                  },
                }}
              >
                Welcome, {userName}
              </Typography>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 1 }}>
                  <Avatar alt={userName} src={""}>
                    {userName.charAt(0).toUpperCase()}
                  </Avatar>
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((item) => (
                    <MenuItem key={item.id} onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        component={Link}
                        to={item.url}
                        sx={{ textDecoration: "none" }}
                      >
                        {item.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
