import dictionary from "../dictionary";
import requestModule from "../methods";

const { admin } = dictionary;
const { get, put, deleteMethod, post } = requestModule;

const AdminService = {
  getProviderListService: (options: {}) => {
    return get(admin.getProviderList(), options);
  },
  getAllServiceData: (options: {}) => {
    return get(admin.getAllServices(), options);
  },
  getAllBookingsService: (options: {}) => {
    return get(admin.getAllBookings(), options);
  },
  updateUserStatusService: (body: {}, options: {}) => {
    return put(admin.updateUserStatus(), body, options);
  },
  updateServiceStatusService: (body: {}, options: {}) => {
    return put(admin.updateServiceStatus(), body, options);
  },
  viewBookingDataById: (id: number, options: {}) => {
    return get(admin.viewBookings(id), options);
  },
  updateBookingService: (id: number, body: {}, options: {}) => {
    return put(admin.updateBookings(id), body, options);
  },
  getBannerService: (options: {}) => {
    return get(admin.getBanner(), options);
  },
  viewBannerService: (id: number, options: {}) => {
    return get(admin.viewBanner(id), options);
  },
  updateBannerService: (body: {}, options: {}) => {
    return put(admin.updateBanner(), body, options);
  },
  deleteBannerService: (id: number, options: {}) => {
    return deleteMethod(admin.deleteBanner(id), options);
  },
  addBannerService: (body: {}, options: {}) => {
    return post(admin.addBanner(), body, options);
  },
  getRefundAndCreditService: (options: {}) => {
    return get(admin.getRefundAndCredit(), options);
  },
};

export default AdminService;
