import Logout from "../../component/logout";
import Availability from "./service-provider/availability";
import ServiceProviderDashboard from "./service-provider/dashboard";
import Documents from "./service-provider/documents";
import Earnings from "./service-provider/earnings";
import Profile from "./service-provider/profile";
import Reviews from "./service-provider/reviews";
import ServiceHistory from "./service-provider/service-history";
import Dashboard from "./MainDashboard";
import MyProfile from "./my-profile";
import MyServices from "./my-services";
import RefundAndCredits from "./refund-and-credits";
import Services from "./service-provider/services";
import BankingDeatils from "./service-provider/banking-details";
import PaymentMethod from "./payment-method";
import ChangePassword from "./component/ChangePassword";
import AllServices from "./component/admin/all-services";
import ProviderList from "./component/admin/provider-list";
import Banner from "./component/admin/banner";
import AllRefundAndCredits from "./component/admin/all-refund-and-credit";
import Bookings from "./component/admin/bookings";

const userType: string = "service_provider";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      userType &&
        userType === "service_provider" && {
          path: "",
          element: <ServiceProviderDashboard />,
        },
      {
        path: "/dashboard/my-profile",
        element: <MyProfile />,
      },
      {
        path: "/dashboard/my-services",
        element: <MyServices />,
      },
      {
        path: "/dashboard/refund-and-credits",
        element: <RefundAndCredits />,
      },
      {
        path: "/dashboard/payment-method",
        element: <PaymentMethod />,
      },
      {
        path: "/dashboard/change-password",
        element: (
          <ChangePassword
            userEmail="minee.soni@gmail.com"
            title="Change Password"
            shouldShowForgotButton={false}
          />
        ),
      },
      {
        path: "/dashboard/logout",
        element: <Logout />,
      },
      {
        path: "/dashboard/profile",
        element: <Profile />,
      },
      {
        path: "/dashboard/availability",
        element: <Availability />,
      },
      {
        path: "/dashboard/services",
        element: <Services />,
      },
      {
        path: "/dashboard/earnings",
        element: <Earnings />,
      },
      {
        path: "/dashboard/documents",
        element: <Documents />,
      },
      {
        path: "/dashboard/service-history",
        element: <ServiceHistory />,
      },
      {
        path: "/dashboard/reviews",
        element: <Reviews />,
      },
      {
        path: "/dashboard/banking-details",
        element: <BankingDeatils />,
      },
      //admin route
      {
        path: "/dashboard/all-services",
        element: <AllServices />,
      },
      {
        path: "/dashboard/provider-list",
        element: <ProviderList />,
      },
      {
        path: "/dashboard/banner",
        element: <Banner />,
      },
      {
        path: "/dashboard/all-refund-credit",
        element: <AllRefundAndCredits />,
      },
      {
        path: "/dashboard/bookings",
        element: <Bookings />,
      },
    ],
  },
];
