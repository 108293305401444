import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Rating,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import Services from "../../../../api/services";
import Swal from "sweetalert2";

interface MyFormValues {
  reason: string;
  friendly: boolean;
  workExactly: boolean;
  differentWork: boolean;
  unfriendly: boolean;
  other: boolean;
  rating: number;
  checkboxes: boolean[];
}

interface CompleteServiceProps {
  serviceBookingId: number;
  setServiceHistory: any;
  handleClose: () => void;
}

const ServiceComplete: React.FC<CompleteServiceProps> = ({
  serviceBookingId,
  setServiceHistory,
  handleClose,
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const formik = useFormik<MyFormValues>({
    initialValues: {
      reason: "",
      friendly: false,
      workExactly: false,
      differentWork: false,
      unfriendly: false,
      other: false,
      rating: 0,
      checkboxes: [false, false, false, false, false],
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Reason is required"),
      rating: Yup.number()
        .moreThan(0, "Rating is required")
        .required("Rating is required"),
      checkboxes: Yup.array()
        .of(Yup.boolean())
        .test(
          "atLeastOneCheckbox",
          "At least one checkbox should be checked",
          (value) => Array.isArray(value) && value.some((checked) => checked)
        )
        .nullable()
        .required("At least one checkbox should be checked"),
    }),
    onSubmit: async (values) => {
      if (values) {
        try {
          setIsCompleted(true);
          const tokenString = localStorage.getItem("access_token");
          if (tokenString !== null) {
            const token = JSON.parse(tokenString);
            const data = {
              rating: values.rating,
              different_work: values.differentWork,
              friendly: values.friendly,
              other: values.other,
              unfriendly: values.unfriendly,
              work_exactly: values.workExactly,
              comment: values.reason,
              bookingId: serviceBookingId,
            };
            const options = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const result = await Services.provider.postCompleteServiceData(
              data,
              options
            );
            if (result) {
              const response = await Services.provider.getServiceHistoryData(
                options
              );
              setServiceHistory(response.data.data);
            }
            Swal.fire("Completed!", "Service has been completed.", "success");
          }
          handleClose();
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsCompleted(false);
        }
      }
    },
  });

  const handleCheckboxChange = (name: string) => {
    const checkboxIndex = [
      "friendly",
      "workExactly",
      "differentWork",
      "unfriendly",
      "other",
    ].indexOf(name);

    formik.handleChange({
      target: {
        name,
        value: !formik.values[name as keyof typeof formik.values],
      },
    });

    // Manually update checkboxes array in formik.values
    formik.setFieldValue("checkboxes", [
      ...formik.values.checkboxes.slice(0, checkboxIndex),
      !formik.values[name as keyof typeof formik.values],
      ...formik.values.checkboxes.slice(checkboxIndex + 1),
    ]);
  };

  const renderCheckbox = (name: keyof MyFormValues, label: string) => (
    <FormControlLabel
      key={name}
      control={
        <Checkbox
          name={name}
          checked={Boolean(formik.values[name as keyof typeof formik.values])}
          onChange={() => handleCheckboxChange(name)}
        />
      }
      label={label}
      htmlFor={name}
    />
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
        <Typography>Tell us about your experience</Typography>

        <Typography component="span">
          <Rating
            name="rating"
            value={formik.values.rating}
            onChange={(_, newValue) => formik.setFieldValue("rating", newValue)}
            onBlur={formik.handleBlur}
            precision={0.5}
          />
          {formik.touched.rating && formik.errors.rating && (
            <Typography className="error">{formik.errors.rating}</Typography>
          )}
        </Typography>

        <FormGroup>
          {[
            ["friendly", "Friendly"],
            ["workExactly", "Work was exactly what requested"],
            ["differentWork", "Different work"],
            ["unfriendly", "Unfriendly"],
            ["other", "Other"],
          ].map(([name, label]) =>
            renderCheckbox(name as keyof MyFormValues, label)
          )}
        </FormGroup>

        {formik.touched.checkboxes && formik.errors.checkboxes && (
          <Typography className="error">{formik.errors.checkboxes}</Typography>
        )}

        <FormControl>
          <textarea
            id="form_reason"
            className="form-control"
            name="reason"
            placeholder="Type something"
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reason}
            style={{ height: "100px", resize: "none" }}
          ></textarea>
          {formik.touched.reason && formik.errors.reason && (
            <Typography className="error">{formik.errors.reason}</Typography>
          )}
        </FormControl>

        <Box sx={{ textAlign: "right" }}>
          <Button type="submit" variant="contained" disabled={isCompleted}>
            {isCompleted ? (
              <span style={{ color: "#837373" }}>Submitting...</span>
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ServiceComplete;
