import dictionary from "../dictionary";
import requestModule from "../methods";

const { home } = dictionary;
const { get, post } = requestModule;

const HomeService = {
  homeBannerImages: () => {
    return get(home.bannerImages());
  },
  getServiceNames: () => {
    return get(home.serviceNames());
  },
  getProviderStatus: () => {
    return get(home.providerStatus());
  },
  postQuickQuote: (body: {}, options: {}) => {
    return post(home.quickQuote(), body, options);
  },
  postContactUs: (body: {}) => {
    return post(home.contactUs(), body);
  },
};

export default HomeService;
