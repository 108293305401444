import {
  Button,
  Checkbox,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import ListingTable from "../../component/ListingTable";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dayjs from "dayjs";
import Drawer from "../../component/Drawer";
import Swal from "sweetalert2";
import Services from "../../../../api/services";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Column {
  id: "date" | "timeSlots" | "action";
  label: string;
  minWidth?: number;
}

const columns: Column[] = [
  { id: "date", label: "Date", minWidth: 100 },
  { id: "timeSlots", label: "Time Slots", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 150 },
];

interface Data {
  id: number;
  date: string;
  timeSlots: string;
  action: React.ReactNode;
}

function createData(
  id: number,
  date: string,
  timeSlots: string,
  action: React.ReactNode
): Data {
  return { id, date, timeSlots, action };
}

interface FormDataProps {
  id: number;
  availabilityDate: any;
  availabilityTime: any;
}

const Availability: React.FC = () => {
  const [availabilityData, setAvailabilityData] = useState([{}]);

  // const availabilityData = [
  //   {
  //     id: 1,
  //     date: "21-Apr-2024",
  //     timeSlot: "08:00 AM To 12:00 PM",
  //   },
  //   {
  //     id: 2,
  //     date: "22-Apr-2024",
  //     timeSlot: "08:00 AM To 12:00 PM, 12:00 PM To 04:00 PM",
  //   },
  //   {
  //     id: 3,
  //     date: "23-Apr-2024",
  //     timeSlot: "12:00 PM To 04:00 PM",
  //   },
  //   {
  //     id: 4,
  //     date: "24-Apr-2024",
  //     timeSlot: "04:00 PM To 08:00 PM, 12:00 PM To 04:00 PM",
  //   },
  //   {
  //     id: 5,
  //     date: "25-Apr-2024",
  //     timeSlot: "08:00 AM To 12:00 PM",
  //   },
  //   {
  //     id: 6,
  //     date: "26-Apr-2024",
  //     timeSlot: "08:00 AM To 12:00 PM",
  //   },
  //   {
  //     id: 7,
  //     date: "27-Apr-2024",
  //     timeSlot:
  //       "08:00 AM To 12:00 PM, 12:00 PM To 04:00 PM, 04:00 PM To 08:00 PM",
  //   },
  // ];
  const [showCalender, setShowCalender] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<FormDataProps>>({});
  const [formData, setFormData] = useState<FormDataProps>({
    id: 0,
    availabilityDate: Dayjs(new Date()),
    availabilityTime: [],
  });

  const handleDateChange = (date: any) => {
    setFormData((prevData) => ({ ...prevData, availabilityDate: date }));
  };

  const handleTimeSlots = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleAvailability = (id: number, date: any, time: any) => {
    const newTime = typeof time === "string" ? time.split(",") : time;
    setFormData((prevData) => ({ ...prevData, id: id }));
    setFormData((prevData) => ({ ...prevData, availabilityDate: date }));
    setFormData((prevData) => ({ ...prevData, availabilityTime: newTime }));
    setShowCalender(!showCalender);
  };

  const handleClose = () => {
    setShowCalender(false);
  };

  const validateForm = () => {
    const newErrors: Partial<FormDataProps> = {};

    if (formData.availabilityTime.length === 0) {
      newErrors.availabilityTime = "Availability time is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      const dynamicValue = `${formData.availabilityDate.format(
        "DD MMM YYYY"
      )} - ${formData.availabilityTime}`;

      const data = {
        date: formData.availabilityDate.format("DD-MMM-YYYY"),
        time: Array.isArray(formData.availabilityTime)
          ? formData.availabilityTime.join(", ")
          : "",
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateAvailabilityService(
            formData.id,
            data,
            options
          );

          if (response.status === 200) {
            getAvailability();

            Swal.fire({
              title: `${dynamicValue}`,
              icon: "success",
              confirmButtonColor: "#11b4f5",
            });
            setShowCalender(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  const getAvailability = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.provider.getAvailabilityService(
          options
        );
        setAvailabilityData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAvailability();
  }, []);

  const rows = availabilityData.map((item: any) => {
    const rowData = {
      id: item.id, // Assuming the index starts from 1
      date: item.date, // Assuming 'name' is a property in your cardData item
      timeSlot: item.time_shift, // Assuming 'cardNumber' is a property in your cardData item
    };

    return createData(
      rowData.id,
      rowData.date,
      rowData.timeSlot,
      <Button
        onClick={() =>
          handleAvailability(
            rowData.id,
            Dayjs(new Date(rowData.date)),
            rowData.timeSlot.split(", ")
          )
        }
      >
        Change Availability
      </Button>
    );
  });

  const timeSlotsData = [
    "08:00 AM To 12:00 PM",
    "12:00 PM To 04:00 PM",
    "04:00 PM To 08:00 PM",
  ];

  return (
    <Box sx={{ py: 2 }}>
      <Typography sx={{ fontSize: "24px", mb: 2, fontWeight: 600 }}>
        Availability
      </Typography>

      <ListingTable
        columns={columns}
        rows={rows}
        shouldShowPagination={false}
        customPadding="4px"
      />

      <Drawer
        isOpen={showCalender}
        title="Availability"
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                disabled
                value={formData.availabilityDate}
                onChange={handleDateChange}
                openTo={"day"}
                displayStaticWrapperAs="desktop"
              />
            </LocalizationProvider>

            <Box sx={{ width: "100%" }}>
              <FormLabel sx={{ display: "block" }}>Time Slots</FormLabel>
              <Select
                name="availabilityTime"
                value={formData.availabilityTime}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                displayEmpty
                defaultValue="Select Time Slots"
                onChange={handleTimeSlots}
                renderValue={(selected) =>
                  Array.isArray(selected) ? selected.join(", ") : ""
                }
                MenuProps={MenuProps}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    width: "100%",
                    height: "48px",
                    fontSize: "10px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                      height: "48px",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={""} disabled>
                  Select Time Slots
                </MenuItem>
                {timeSlotsData.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={formData.availabilityTime.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>

              <Button
                type="submit"
                disabled={isSubmitted}
                variant="contained"
                sx={{
                  "&.MuiButtonBase-root": {
                    borderRadius: "30px",
                    mt: 2,
                  },
                }}
              >
                {isSubmitted ? (
                  <span style={{ color: "#fff" }}>Submitting...</span>
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </Stack>
        </form>
      </Drawer>
    </Box>
  );
};

export default Availability;
