export const MENU = [
  {
    id: 1,
    url: "/services",
    label: "SERVICES",
  },
  {
    id: 2,
    url: "/quick-quote",
    label: "QUICK QUOTE",
  },
  {
    id: 3,
    url: "/become-helping-hand",
    label: "BECOME HELPING HAND",
  },
  {
    id: 4,
    url: "/about-us",
    label: "ABOUT US",
  },
  {
    id: 5,
    url: "/contact-us",
    label: "CONTACT US",
  },
];

export const state = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Nova Scotia",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
];
