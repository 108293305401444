import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Services from "../../../api/services";
import Swal from "sweetalert2";

const cancelData = [
  {
    id: 1,
    name: "Helping Hand Credit",
    notification: "Instantly added to your account",
  },
  {
    id: 2,
    name: "Refund",
    notification:
      "Refund may take up to 5-7 business days to show in your account",
  },
];

interface ServiceCancelProps {
  handleClose: () => void;
  serviceBookingId: number;
  setServiceData: any;
}

const CancelService: React.FC<ServiceCancelProps> = ({
  handleClose,
  serviceBookingId,
  setServiceData,
}) => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [notification, setNotification] = useState("");
  const formik = useFormik({
    initialValues: {
      reason: "",
      refundType: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Reason is required"),
      refundType: Yup.string().required("Refund Type is required"),
    }),
    onSubmit: async (values) => {
      if (values) {
        try {
          setIsCancelled(true);
          const tokenString = localStorage.getItem("access_token");
          if (tokenString !== null) {
            const token = JSON.parse(tokenString);
            const data = {
              status: "cancelled",
              cancel_reason: values.reason,
              refund_type: values.refundType,
            };
            const options = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const result = await Services.provider.updateUpcomingServicesData(
              serviceBookingId,
              data,
              options
            );
            if (result) {
              const response = await Services.customer.getMyServiceData(
                options
              );
              setServiceData(response.data.data);
            }
            Swal.fire({
              text: "Thank you for raising your concern, we will get back to you with more information soon.",
              icon: "success",
              confirmButtonColor: "#11b4f5",
            });
          }
          handleClose();
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsCancelled(false);
        }
      }
    },
  });

  const handleRefundType = (event: SelectChangeEvent) => {
    const refundType = event.target.value;
    const notificationData = cancelData.filter(
      (item) => item.name === refundType
    );
    setNotification(notificationData[0].notification);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={"column"} spacing={2} sx={{ mt: 2 }}>
        <FormControl>
          <FormLabel sx={{ mb: 1 }}>Why do you want to cancel?</FormLabel>
          <textarea
            id="form_reason"
            className="form-control"
            name="reason"
            placeholder="reason"
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reason}
            style={{ height: "100px", resize: "none" }}
          ></textarea>
          {formik.touched.reason &&
            typeof formik.errors.reason === "string" && (
              <Typography className="error">{formik.errors.reason}</Typography>
            )}
        </FormControl>

        <Box
          sx={{
            "& .MuiFormLabel-root": { marginBottom: "8px", display: "block" },
          }}
        >
          <FormLabel>Refund Type</FormLabel>
          <Select
            fullWidth
            name="refundType"
            displayEmpty
            defaultValue=""
            onChange={(event) => {
              formik.handleChange(event);
              handleRefundType(event);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.refundType}
            sx={{
              "&.MuiInputBase-root": {
                border: "1px solid #ccc",
                backgroundColor: "#FFF",
                width: "100%",
                height: "48px",
                "@media (max-width: 992px)": {
                  width: "100%",
                  height: "48px",
                },
              },
              fieldset: {
                display: "none",
              },
            }}
          >
            <MenuItem value={""} disabled>
              Please select
            </MenuItem>
            {cancelData.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.refundType &&
            typeof formik.errors.refundType === "string" && (
              <Typography className="error">
                {formik.errors.refundType}
              </Typography>
            )}
        </Box>

        <Typography sx={{ fontSize: "12px" }}>
          {notification.trim() !== "" ? `Note: ${notification}` : ""}
        </Typography>

        <Box sx={{ textAlign: "right" }}>
          <Button type="submit" variant="contained" disabled={isCancelled}>
            {isCancelled ? (
              <span style={{ color: "#837373" }}>Submitting...</span>
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default CancelService;
