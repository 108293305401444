import api from "../client";
import { AxiosResponse } from "axios";

interface RequestOptions {
  headers?: Record<string, string>;
  // Add other request options as needed
}

const requestModule = {
  post: async (
    url: string,
    body: object,
    options?: RequestOptions
  ): Promise<AxiosResponse> => {
    try {
      return await api.post(url, body, options);
    } catch (error) {
      handleRequestError(error);
      throw error;
    }
  },

  get: async (
    url: string,
    options?: RequestOptions
  ): Promise<AxiosResponse> => {
    try {
      return await api.get(url, options);
    } catch (error) {
      handleRequestError(error);
      throw error;
    }
  },

  put: async (
    url: string,
    body: object,
    options?: RequestOptions
  ): Promise<AxiosResponse> => {
    try {
      return await api.put(url, body, options);
    } catch (error) {
      handleRequestError(error);
      throw error;
    }
  },

  deleteMethod: async (
    url: string,
    options?: RequestOptions
  ): Promise<AxiosResponse> => {
    try {
      return await api.delete(url, options);
    } catch (error) {
      handleRequestError(error);
      throw error;
    }
  },

  patch: async (
    url: string,
    body: object,
    options?: RequestOptions
  ): Promise<AxiosResponse> => {
    try {
      return await api.patch(url, body, options);
    } catch (error) {
      handleRequestError(error);
      throw error;
    }
  },
};

export default requestModule;

function handleRequestError(error: any): void {
  // Assuming you want to log the error message
  if (error.response) {
    console.error("Request failed with status code:", error.response.status);
  } else if (error.request) {
    console.error("Request failed:", error.request);
  } else {
    console.error("Request failed:", error.message);
  }
}
