const CustomerDictionary = {
  customerRegistration: () => "/register/customer",
  getAllPaymentMethods: () => "/serviceprovider/get_all_card_info",
  addPaymentMethod: () => "serviceprovider/payment_methods",
  updatePaymentMethod: (id: number) => `serviceprovider/payment_methods/${id}`,
  deletePaymentMethod: (id: number) => `serviceprovider/delete_card_info/${id}`,
  viewPaymentMethod: (id: number) => `serviceprovider/get_card_info/${id}`,
  getMyService: () => "/user/my_services",
  getMyRefund: () => "/user/my_refund",
  getCustomerFeedbackStatus: () => "/user/get_customer_feedback_status",
  updateCustomerFeedback: () => "/user/update_customer_feedback_status",
};

export default CustomerDictionary;
