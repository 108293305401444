import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState } from "react";
import { IMAGES } from "../../../../assets/images";
import ProfileEdit from "../../my-profile/profileEdit";
import Drawer from "../Drawer";
import ChangePassword from "../ChangePassword";
import { useSelector } from "react-redux";

interface myProfileProps {
  shouldShowChangePassword?: boolean;
}
const MyProfileComponent: React.FC<myProfileProps> = ({
  shouldShowChangePassword = false,
}) => {
  const userDetails = useSelector((state: any) => state.userData);
  const [open, setOpen] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePassword = () => {
    setChangePassword(!changePassword);
  };

  return (
    <>
      <Box>
        <Typography variant="h2" sx={{ fontSize: "24px" }}>
          My Profile
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                  <img
                    alt=""
                    src={
                      userDetails.profile_image
                        ? `${process.env.REACT_APP_BACKEND_BASE_URL}upload/${userDetails.profile_image}`
                        : IMAGES.Team1
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      "& span": {
                        fontFamily: "'Poppins',sans-serif",
                        fontSize: "16px",
                        "&:first-of-type": { width: "17%" },
                        "@media (max-width:600px)": {
                          fontSize: "12px",
                        },
                      },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontFamily: "'Poppins',sans-serif",
                        fontSize: "18px",
                      }}
                    >
                      {userDetails.first_name} {userDetails.last_name}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="ceter">
                      <Box component={"span"}>Email Address:</Box>
                      <Box component={"span"}>{userDetails.email}</Box>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="ceter">
                      <Box component={"span"}>Phone Number:</Box>
                      <Box component={"span"}>{userDetails.phone_number}</Box>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="ceter">
                      <Box component={"span"}>Address:</Box>
                      <Box component={"span"}>
                        {userDetails.landmark_1}
                        {", "}
                        {userDetails.landmark_2}
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="ceter">
                      <Box component={"span"}>City:</Box>
                      <Box component={"span"}>{userDetails.city}</Box>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="ceter">
                      <Box component={"span"}>Province:</Box>
                      <Box component={"span"}>{userDetails.state}</Box>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="ceter">
                      <Box component={"span"}>Postal Code:</Box>
                      <Box component={"span"}>{userDetails.pincode}</Box>
                    </Stack>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={2}
                      alignItems="center"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          width: "150px",
                          "@media(max-width:768px)": {
                            width: "100%",
                          },
                        }}
                        onClick={handleClickOpen}
                      >
                        Edit
                      </Button>
                      {shouldShowChangePassword && (
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            "@media(max-width:768px)": {
                              width: "100%",
                            },
                          }}
                          onClick={handleChangePassword}
                        >
                          Change Password
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Drawer isOpen={open} handleClose={handleClose} title="Edit Profile">
        <ProfileEdit userDetails={userDetails} handleClose={handleClose} />
      </Drawer>
      <Drawer isOpen={changePassword} handleClose={handleChangePassword}>
        <ChangePassword
          title="Change Password"
          userEmail={userDetails.email}
          handleClose={handleChangePassword}
          shouldShowForgotButton={false}
        />
      </Drawer>
    </>
  );
};

export default MyProfileComponent;
