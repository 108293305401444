import dictionary from "../dictionary";
import requestModule from "../methods";

const { customer } = dictionary;
const { post, get, put, deleteMethod } = requestModule;

const CustomerService = {
  customerRegisterService: (body: {}) => {
    return post(customer.customerRegistration(), body);
  },
  getAllPaymentMethodsService: (options: {}) => {
    return get(customer.getAllPaymentMethods(), options);
  },
  deletePaymentMethodService: (id: number, options: {}) => {
    return deleteMethod(customer.deletePaymentMethod(id), options);
  },
  addPaymentMethodService: (body: {}, options: {}) => {
    return post(customer.addPaymentMethod(), body, options);
  },
  updatePaymentMethodService: (id: number, body: {}, options: {}) => {
    return put(customer.updatePaymentMethod(id), body, options);
  },
  viewPaymentMethodService: (id: number, options: {}) => {
    return get(customer.viewPaymentMethod(id), options);
  },
  getMyServiceData: (options: {}) => {
    return get(customer.getMyService(), options);
  },
  getMyRefundData: (options: {}) => {
    return get(customer.getMyRefund(), options);
  },
  getCustomerFeedbackStatusService: (options: {}) => {
    return get(customer.getCustomerFeedbackStatus(), options);
  },
  updateCustomerFeedbackService: (body: {}, options: {}) => {
    return post(customer.updateCustomerFeedback(), body, options);
  },
};

export default CustomerService;
