import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Input from "../../../../../component/input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface SnowRemovalProps {
  formik: any;
}

const SnowRemoval: React.FC<SnowRemovalProps> = ({ formik }) => {
  const handleIconClick = () => {
    formik.setFieldValue("noOfCarsDriveWay", [
      ...formik.values.noOfCarsDriveWay,
      "",
    ]);
    formik.setFieldValue("noOfCrDriveWayPrice", [
      ...formik.values.noOfCrDriveWayPrice,
      "",
    ]);
  };

  const handleRemoveClick = (id: number) => {
    const newNoOfCarsDriveWay = [...formik.values.noOfCarsDriveWay];
    newNoOfCarsDriveWay.splice(id, 1);
    const newNoOfCrDriveWayPrice = [...formik.values.noOfCrDriveWayPrice];
    newNoOfCrDriveWayPrice.splice(id, 1);
    formik.setFieldValue("noOfCarsDriveWay", newNoOfCarsDriveWay);
    formik.setFieldValue("noOfCrDriveWayPrice", newNoOfCrDriveWayPrice);
  };

  const handlePriceChange = (itemId: number, newValue: any) => {
    const newPrices = [...formik.values.noOfCrDriveWayPrice];
    newPrices[itemId] = newValue;
    formik.setFieldValue("noOfCrDriveWayPrice", newPrices);
  };

  const handleNoOfCarsDriveWayChange = (itemId: number, newValue: any) => {
    const newNoOfCarsDriveWay = [...formik.values.noOfCarsDriveWay];
    newNoOfCarsDriveWay[itemId] = newValue;
    formik.setFieldValue("noOfCarsDriveWay", newNoOfCarsDriveWay);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Description</b>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Price</b>
        </Grid>

        {Array.isArray(formik.values.noOfCarsDriveWay) &&
          formik.values.noOfCarsDriveWay.map((item: any, index: number) => (
            <React.Fragment key={item.id}>
              <Grid item xs={6} sm={6} lg={6}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Typography component={"span"}>
                    No of car's driveway
                  </Typography>
                  <Box>
                    <Select
                      size="small"
                      name="noOfCarsDriveWay"
                      value={formik.values.noOfCarsDriveWay[index]}
                      onChange={(e) =>
                        handleNoOfCarsDriveWayChange(index, e.target.value)
                      }
                      sx={{ width: 150, height: "48px" }}
                      displayEmpty
                      defaultValue=""
                    >
                      <MenuItem disabled value="">
                        Please select
                      </MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                    </Select>
                    {formik.touched.noOfCarsDriveWay &&
                      formik.errors.noOfCarsDriveWay && (
                        <Typography className="error">
                          {formik.errors.noOfCarsDriveWay}
                        </Typography>
                      )}
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                  <Typography component={"span"}>$</Typography>
                  <Box>
                    <Input
                      type="text"
                      name="noOfCrDriveWayPrice"
                      value={formik.values.noOfCrDriveWayPrice[index]}
                      onChange={(e: any) =>
                        handlePriceChange(index, e.target.value)
                      }
                    />
                    {formik.touched.noOfCrDriveWayPrice &&
                      formik.errors.noOfCrDriveWayPrice && (
                        <Typography className="error">
                          {formik.errors.noOfCrDriveWayPrice}
                        </Typography>
                      )}
                  </Box>
                  {index === 0 ? (
                    <IconButton
                      onClick={
                        formik.values.noOfCarsDriveWay.length < 3
                          ? handleIconClick
                          : undefined
                      }
                      sx={{
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                        "&:hover": {
                          backgroundColor: "#11b4f5",
                          color: "#FFF",
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleRemoveClick(index)}
                      sx={{
                        backgroundColor: "red",
                        color: "#FFF",
                        "&:hover": {
                          backgroundColor: "red",
                          color: "#FFF",
                        },
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            </React.Fragment>
          ))}

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Black Ice</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="blackIcePrice"
                value={formik.values.blackIcePrice}
                onChange={formik.handleChange}
              />
              {formik.touched.blackIcePrice && formik.errors.blackIcePrice && (
                <Typography className="error">
                  {formik.errors.blackIcePrice}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Corner House</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="cornerHousePrice"
                value={formik.values.cornerHousePrice}
                onChange={formik.handleChange}
              />
              {formik.touched.cornerHousePrice &&
                formik.errors.cornerHousePrice && (
                  <Typography className="error">
                    {formik.errors.cornerHousePrice}
                  </Typography>
                )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Snow more than 8 inches</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="snowSizePrice"
                value={formik.values.snowSizePrice}
                onChange={formik.handleChange}
              />
              {formik.touched.snowSizePrice && formik.errors.snowSizePrice && (
                <Typography className="error">
                  {formik.errors.snowSizePrice}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SnowRemoval;
