import { Rating, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import Services from "../../../../api/services";

const Reviews: React.FC = () => {
  const [reviewData, setReviewData] = useState([{}]);

  const getReviews = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.provider.getReviewsData(options);
        setReviewData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${String(hours).padStart(2, "0")}:${minutes} ${ampm}`;

    return `${day} ${month} ${year} ${strTime}`;
  };

  return (
    <>
      <Typography sx={{ fontSize: "24px", mb: 3, fontWeight: 600 }}>
        Reviews
      </Typography>
      <Stack direction="column" spacing={1}>
        {reviewData &&
          reviewData.map((item: any) => (
            <Box sx={{ borderBottom: "1px solid #ccc", py: 2 }} key={item.id}>
              <Typography variant="h6" sx={{ fontWeight: 800 }}>
                {item.customer_name}
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                  {item.service_name}
                </Typography>
                <Typography component="span">
                  <Rating
                    name="half-rating-read"
                    defaultValue={item.rating}
                    precision={0.5}
                    readOnly
                  />
                </Typography>
              </Stack>
              <Typography component="body" sx={{ fontSize: "12px" }}>
                {item.comment}
              </Typography>
              <Typography component="span" sx={{ fontSize: "10px" }}>
                {formatDate(item.created_at)}
              </Typography>
            </Box>
          ))}
      </Stack>
    </>
  );
};

export default Reviews;
