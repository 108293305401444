import { Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Input from "../../../../../component/input";

interface DrivingInstructorsHourlyProps {
  formik: any;
}

const DrivingInstructorsHourly: React.FC<DrivingInstructorsHourlyProps> = ({
  formik,
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Description</b>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Price</b>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Hourly lesson charges</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="drivingHourlyLessonCharges"
                value={formik.values.drivingHourlyLessonCharges}
                onChange={formik.handleChange}
              />
              {formik.touched.drivingHourlyLessonCharges &&
                formik.errors.drivingHourlyLessonCharges && (
                  <Typography className="error">
                    {formik.errors.drivingHourlyLessonCharges}
                  </Typography>
                )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DrivingInstructorsHourly;
