import styled from "@emotion/styled";

export const StyledProvider = styled("div")(() => ({
  "& .MuiFormLabel-root": {
    marginBottom: "8px",
  },
  "& .MuiFormGroup-root": {
    marginBottom: "16px",
  },
  "& .MuiInputBase-root": {
    border: "1px solid #ccc",
    backgroundColor: "#FFF",
    height: "48px",
    "@media (max-width: 992px)": {
      width: "100%",
    },
  },
  "& .MuiFormHelperText-root": {
    color: "#d32f2f",
  },
  fieldset: {
    display: "none",
  },
}));
