import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Rating,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Services from "../../../api/services";
import { Link } from "react-router-dom";

interface MyFormValues {
  reason: string;
  friendly: boolean;
  punctual: boolean;
  followedInstructions: boolean;
  rating: number;
  checkboxes: boolean[];
}

interface CompleteServiceProps {
  serviceData: any;
  setServiceData: any;
  handleClose: () => void;
  setFeedbackStatus: (value: boolean) => void;
}

const CustomerFeedback: React.FC<CompleteServiceProps> = ({
  serviceData,
  setServiceData,
  handleClose,
  setFeedbackStatus,
}) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const formik = useFormik<MyFormValues>({
    initialValues: {
      reason: "",
      friendly: false,
      followedInstructions: false,
      punctual: false,
      rating: 0,
      checkboxes: [false, false, false],
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Reason is required"),
      rating: Yup.number()
        .moreThan(0, "Rating is required")
        .required("Rating is required"),
      checkboxes: Yup.array()
        .of(Yup.boolean())
        .test(
          "atLeastOneCheckbox",
          "At least one checkbox should be checked",
          (value) => Array.isArray(value) && value.some((checked) => checked)
        )
        .nullable()
        .required("At least one checkbox should be checked"),
    }),
    onSubmit: async (values) => {
      if (values) {
        try {
          setIsCompleted(true);
          const tokenString = localStorage.getItem("access_token");
          if (tokenString !== null) {
            const token = JSON.parse(tokenString);
            const data = {
              rating: values.rating,
              friendly: values.friendly,
              punctual: values.punctual,
              followedInstructions: values.followedInstructions,
              comment: values.reason,
              bookingId: serviceData[0]?.id,
              providerId: serviceData[0]?.provider_id,
            };
            const options = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const result =
              await Services.customer.updateCustomerFeedbackService(
                data,
                options
              );
            if (result) {
              // const response =
              //   await Services.customer.getCustomerFeedbackStatusService(
              //     options
              //   );
              // const feedbackEntries = response.data.data.filter(
              //   (item: any) => item.customer_feedback_status === "1"
              // );
              // if (feedbackEntries.length > 0) {
              //   setFeedbackStatus(true);
              //   setServiceData(feedbackEntries);
              // }
              // navigate("/dashboard/my-profile");
            }
            Swal.fire(
              "Completed!",
              "Feedback has been completed.",
              "success"
            ).then((result) => {
              // Reload the Page
              (window.location as Window["location"]).reload();
            });
          }
          handleClose();
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsCompleted(false);
        }
      }
    },
  });

  const handleCheckboxChange = (name: string) => {
    const checkboxIndex = [
      "friendly",
      "followedInstructions",
      "punctual",
    ].indexOf(name);

    formik.handleChange({
      target: {
        name,
        value: !formik.values[name as keyof typeof formik.values],
      },
    });

    // Manually update checkboxes array in formik.values
    formik.setFieldValue("checkboxes", [
      ...formik.values.checkboxes.slice(0, checkboxIndex),
      !formik.values[name as keyof typeof formik.values],
      ...formik.values.checkboxes.slice(checkboxIndex + 1),
    ]);
  };

  const renderCheckbox = (name: keyof MyFormValues, label: string) => (
    <FormControlLabel
      key={name}
      control={
        <Checkbox
          name={name}
          checked={Boolean(formik.values[name as keyof typeof formik.values])}
          onChange={() => handleCheckboxChange(name)}
        />
      }
      label={label}
      htmlFor={name}
    />
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
        <Typography>Tell us about your experience</Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
          {serviceData[0].service_name} By {serviceData[0].service_provider}
        </Typography>
        <Typography component="span">
          <Rating
            name="rating"
            value={formik.values.rating}
            onChange={(_, newValue) => formik.setFieldValue("rating", newValue)}
            onBlur={formik.handleBlur}
            precision={0.5}
          />
          {formik.touched.rating && formik.errors.rating && (
            <Typography className="error">{formik.errors.rating}</Typography>
          )}
        </Typography>

        <FormGroup>
          {[
            ["friendly", "Friendly"],
            ["followedInstructions", "Followed Instructions"],
            ["punctual", "Punctual"],
          ].map(([name, label]) =>
            renderCheckbox(name as keyof MyFormValues, label)
          )}
        </FormGroup>

        {formik.touched.checkboxes && formik.errors.checkboxes && (
          <Typography className="error">{formik.errors.checkboxes}</Typography>
        )}

        <FormControl>
          <textarea
            id="form_reason"
            className="form-control"
            name="reason"
            placeholder="Type something"
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reason}
            style={{ height: "100px", resize: "none" }}
          ></textarea>
          {formik.touched.reason && formik.errors.reason && (
            <Typography className="error">{formik.errors.reason}</Typography>
          )}
        </FormControl>

        <Stack direction={"row"} spacing={2}>
          <Button type="submit" variant="contained" disabled={isCompleted}>
            {isCompleted ? (
              <span style={{ color: "#837373" }}>Submitting...</span>
            ) : (
              "Submit"
            )}
          </Button>
          <Button to="/contact-us" component={Link} variant="outlined">
            Get Help
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default CustomerFeedback;
