import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import Services from "../../../../../api/services";

interface providerEditProps {
  id: number;
  userStatus: string;
  backgroundCheck: string;
}

interface userDetailsProps {
  userData: any;
  setProviderData: (value: any) => void;
  handleClose: (value: boolean) => void;
}

const ProviderEdit: React.FC<userDetailsProps> = ({
  userData,
  setProviderData,
  handleClose,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<providerEditProps>>({});
  const [formData, setFormData] = useState<providerEditProps>({
    id: userData.id,
    userStatus: userData.userStatus,
    backgroundCheck: userData.backgroundCheck,
  });

  const validateForm = () => {
    const newErrors: Partial<providerEditProps> = {};
    if (!formData.userStatus.trim()) {
      newErrors.userStatus = "User status is required";
    }
   setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      const bodyFormData = {
        id: formData.id,
        user_status: formData.userStatus,
        allow_background_check: formData.backgroundCheck,
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = await Services.admin.updateUserStatusService(
            bodyFormData,
            options
          );
          if (response.status === 200) {
            const providerResponse =
              await Services.admin.getProviderListService(options);
            setProviderData(providerResponse.data.data);
            Swal.fire({
              title: "Thank You!",
              text: "Status updated successfully",
              icon: "success",
              confirmButtonColor: "#11b4f5",
            });
          }
          handleClose(false);
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.message}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel>Background Check</FormLabel>
              <Select
                fullWidth
                name="backgroundCheck"
                value={formData.backgroundCheck}
                displayEmpty
                defaultValue="Please select"
                label="backgroundCheck"
                onChange={handleSelect}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    height: "48px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={""} disabled>
                  Please select
                </MenuItem>
                <MenuItem value={1}>Pending</MenuItem>
                <MenuItem value={2}>Done</MenuItem>
                <MenuItem value={3}>Reject</MenuItem>
              </Select>
              {errors.backgroundCheck && (
                <Typography color="error">{errors.backgroundCheck}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
            <FormLabel>User Status</FormLabel>
              <Select
                fullWidth
                name="userStatus"
                value={formData.userStatus}
                displayEmpty
                defaultValue="Please select"
                label="userStatus"
                onChange={handleSelect}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    height: "48px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={""} disabled>
                  Please select
                </MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="approve">Approve</MenuItem>
                <MenuItem value="reject">Reject</MenuItem>
              </Select>
              {errors.userStatus && (
                <Typography color="error">{errors.userStatus}</Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit" disabled={isSubmitted}>
              {isSubmitted ? (
                <span style={{ color: "#fff" }}>Submitting...</span>
              ) : (
                "Update"
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default ProviderEdit;
