import { Button, FormControl, FormLabel, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Services from "../../../../api/services";

interface ServiceIssueProps {
  handleClose: () => void;
  serviceBookingId: number;
  setServiceHistory: any;
}

const ServiceIssue: React.FC<ServiceIssueProps> = ({
  handleClose,
  serviceBookingId,
  setServiceHistory,
}) => {
  const [isIssue, setIsIssue] = useState(false);
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object({
      reason: Yup.string().required("Field is required"),
    }),
    onSubmit: async (values) => {
      if (values) {
        try {
          setIsIssue(true);
          const tokenString = localStorage.getItem("access_token");
          if (tokenString !== null) {
            const token = JSON.parse(tokenString);
            const data = {
              status: "issue",
              issue_reason: values.reason,
            };
            const options = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            const result = await Services.provider.updateUpcomingServicesData(
              serviceBookingId,
              data,
              options
            );
            if (result) {
              const response = await Services.provider.getServiceHistoryData(
                options
              );
              setServiceHistory(response.data.data);
            }
            Swal.fire({
              text: "Thank you for raising your concern, we will get back to you with more information soon.",
              icon: "success",
              confirmButtonColor: "#11b4f5",
            });
          }
          handleClose();
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsIssue(false);
        }
      }
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction={"column"} spacing={2} sx={{ mt: 2 }}>
        <FormControl>
          <FormLabel sx={{ mb: 1 }}>Tell us more</FormLabel>
          <textarea
            id="form_reason"
            className="form-control"
            name="reason"
            placeholder="Type"
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reason}
            style={{ height: "100px", resize: "none" }}
          ></textarea>
          {formik.touched.reason &&
            typeof formik.errors.reason === "string" && (
              <Typography className="error">{formik.errors.reason}</Typography>
            )}
        </FormControl>

        <Box sx={{ textAlign: "right" }}>
          <Button type="submit" variant="contained" disabled={isIssue}>
            {isIssue ? (
              <span style={{ color: "#837373" }}>Submitting...</span>
            ) : (
              "Submit"
            )}
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default ServiceIssue;
