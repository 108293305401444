import React, { useEffect, useState } from "react";
import ListingTable from "../../component/ListingTable";
import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import Drawer from "../../component/Drawer";
import ServiceIssue from "./service-issue";
import ServiceComplete from "./service-complete";
import Services from "../../../../api/services";
interface Column {
  id: "serviceName" | "customerName" | "address" | "schedule" | "status";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [
  { id: "serviceName", label: "Service Name", minWidth: 170 },
  { id: "customerName", label: "Customer Name", minWidth: 100 },
  { id: "address", label: "Address", minWidth: 170 },
  { id: "schedule", label: "Schedule", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 170 },
];

interface Data {
  id: number;
  serviceName: string;
  customerName: string;
  address: string;
  schedule: string;
  status: React.ReactNode;
}

function createData(
  id: number,
  serviceName: string,
  customerName: string,
  address: string,
  schedule: string,
  status: React.ReactNode
): Data {
  return { id, serviceName, customerName, address, schedule, status };
}

const ServiceHistory: React.FC = () => {
  const [serviceHistory, setServiceHistory] = useState([]);
  const [serviceBookingId, setServiceBookingId] = useState(0);
  const [serviceComplete, setServiceComplete] = useState(false);
  const [serviceIssue, setServiceIssue] = useState(false);

  const handleComplete = (bookingId: number) => {
    setServiceBookingId(bookingId);
    setServiceComplete(!serviceComplete);
  };

  const handleIssue = (bookingId: number) => {
    setServiceBookingId(bookingId);
    setServiceIssue(!serviceIssue);
  };

  const rows = serviceHistory.map((item: any, index: number) => {
    const rowData = {
      index: index + 1,
      serviceName: item.service_name,
      customerName: item.name,
      address: item.address,
      serviceDateTime: item.service_date_time,
      bookingId: item.id,
      status: item.status,
    };

    return createData(
      rowData.index,
      rowData.serviceName,
      rowData.customerName,
      rowData.address,
      rowData.serviceDateTime,
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {rowData.status === "accepted" ? (
          <>
            <Button
              variant="contained"
              onClick={() => handleComplete(rowData.bookingId)}
              sx={{
                "&.MuiButtonBase-root": {
                  fontSize: "12px",
                },
              }}
            >
              Complete
            </Button>

            <Button
              onClick={() => handleIssue(rowData.bookingId)}
              sx={{
                background: "#ef5350",
                color: "#fff",
                borderRadius: "30px",
                textTransform: "uppercase",
                padding: "6px 16px",
                fontSize: "12px",
                "&:hover": {
                  background: "#ef5350",
                  color: "#fff",
                },
              }}
            >
              Issue
            </Button>
          </>
        ) : (
          <Box component={"span"} sx={{ color: "green" }}>
            Completed
          </Box>
        )}
      </Stack>
    );
  });

  const fetchServiceHistoryData = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await Services.provider.getServiceHistoryData(options);
        setServiceHistory(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchServiceHistoryData();
  }, []);

  return (
    <>
      <Box sx={{ py: 2 }}>
        <Typography variant="h2" sx={{ fontSize: "24px", mb: 3 }}>
          Service History
        </Typography>
        <ListingTable columns={columns} rows={rows} />
      </Box>
      <Drawer
        isOpen={serviceComplete}
        handleClose={() => handleComplete(serviceBookingId)}
        title="Feedback Form"
      >
        <ServiceComplete
          serviceBookingId={serviceBookingId}
          setServiceHistory={setServiceHistory}
          handleClose={() => handleComplete(serviceBookingId)}
        />
      </Drawer>
      <Drawer
        isOpen={serviceIssue}
        handleClose={() => handleIssue(serviceBookingId)}
        title="Service Issue"
      >
        <ServiceIssue
          handleClose={() => handleIssue(serviceBookingId)}
          serviceBookingId={serviceBookingId}
          setServiceHistory={setServiceHistory}
        />
      </Drawer>
    </>
  );
};

export default ServiceHistory;
