import {
  Button,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Input from "../../../../component/input";
import Swal from "sweetalert2";
import Services from "../../../../api/services";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

interface driverLicenseProps {
  driverLicenseFront: any;
  driverLicenseBack: any;
}
interface props {
  handleClose: (value: boolean) => void;
}

const DriverLicense: React.FC<props> = ({ handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<driverLicenseProps>>({});
  const [formData, setFormData] = useState<driverLicenseProps>({
    driverLicenseFront: "",
    driverLicenseBack: "",
  });

  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [backImage, setBackImage] = useState<string | null>(null);

  const validateForm = () => {
    const newErrors: Partial<driverLicenseProps> = {};

    if (!formData.driverLicenseFront) {
      newErrors.driverLicenseFront = "Driver License Front is required";
    }

    if (!formData.driverLicenseBack) {
      newErrors.driverLicenseBack = "Driver License Back is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement; // Explicit casting to HTMLInputElement
    if (files && files.length > 0) {
      // Handle file selection
      const selectedFile = files[0];
      setFormData((prevData) => ({ ...prevData, [name]: selectedFile }));
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          if (name === "driverLicenseFront") {
            setFrontImage(event.target.result as string);
          } else {
            setBackImage(event.target.result as string);
          }
        }
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Handle other input types
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const removeImage = (imageType: "front" | "back") => {
    if (imageType === "front") {
      setFrontImage(null);
      const frontInput = document.querySelector(
        'input[name="driverLicenseFront"]'
      ) as HTMLInputElement;
      if (frontInput) {
        frontInput.value = ""; // Clear the input value
      }
    } else {
      setBackImage(null);
      const backInput = document.querySelector(
        'input[name="driverLicenseBack"]'
      ) as HTMLInputElement;
      if (backInput) {
        backInput.value = ""; // Clear the input value
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      var data = new FormData();
      data.append("driverLicenseBack", formData.driverLicenseFront);
      data.append("driverLicenseFront", formData.driverLicenseBack);

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateDrivingLicenseService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "Driver license has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <FormGroup sx={{ my: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Upload photo Front</FormLabel>
              <Input
                type="file"
                name="driverLicenseFront"
                // value={formData.driverLicenseFront}
                onChange={handleInputChange}
              />
              {frontImage && (
                <Box
                  sx={{
                    width: "150px",
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    mt: 2,
                    position: "relative",
                  }}
                >
                  <IconButton
                    disableRipple={true}
                    sx={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      zIndex: 10,
                      width: "20px",
                      height: "20px",
                      background: "#11b4f5",
                      color: "#fff",
                    }}
                    onClick={() => removeImage("front")}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <img
                    src={frontImage}
                    alt="Preview"
                    style={{ maxWidth: "100%", display: "inherit" }}
                  />
                </Box>
              )}
              {errors.driverLicenseFront && (
                <Typography color="error">
                  {errors.driverLicenseFront}
                </Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormGroup sx={{ my: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Upload Photo Back</FormLabel>
              <Input
                type="file"
                name="driverLicenseBack"
                // value={formData.driverLicenseBack}
                onChange={handleInputChange}
              />
              {backImage && (
                <Box
                  sx={{
                    width: "150px",
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    mt: 2,
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => removeImage("back")}
                    disableRipple={true}
                    sx={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      zIndex: 10,
                      width: "20px",
                      height: "20px",
                      background: "#11b4f5",
                      color: "#fff",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <img
                    src={backImage}
                    alt="Preview"
                    style={{ maxWidth: "100%", display: "inherit" }}
                  />
                </Box>
              )}
              {errors.driverLicenseBack && (
                <Typography color="error">
                  {errors.driverLicenseBack}
                </Typography>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" disabled={isSubmitted}>
          {isSubmitted ? (
            <span style={{ color: "#fff" }}>Submitting...</span>
          ) : (
            "Submit"
          )}
        </Button>
      </form>
    </>
  );
};

export default DriverLicense;
