import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Input from "../../../../../component/input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
interface InHomeSalonWomenProps {
  formik: any;
}

const InHomeSalonWomen: React.FC<InHomeSalonWomenProps> = ({ formik }) => {
  const handleAddButton = (id: number) => {
    if (id === 1) {
      formik.setFieldValue("threading", [...formik.values.threading, ""]);
      formik.setFieldValue("threadingPrice", [
        ...formik.values.threadingPrice,
        "",
      ]);
    }

    if (id === 2) {
      formik.setFieldValue("waxing", [...formik.values.waxing, ""]);
      formik.setFieldValue("waxingPrice", [...formik.values.waxingPrice, ""]);
    }

    if (id === 3) {
      formik.setFieldValue("eyebrows", [...formik.values.eyebrows, ""]);
      formik.setFieldValue("eyebrowsPrice", [
        ...formik.values.eyebrowsPrice,
        "",
      ]);
    }

    if (id === 4) {
      formik.setFieldValue("eyeLashes", [...formik.values.eyeLashes, ""]);
      formik.setFieldValue("eyeLashesPrice", [
        ...formik.values.eyeLashesPrice,
        "",
      ]);
    }
  };

  const handleRemoveButton = (id: number, itemId: number) => {
    if (itemId === 1) {
      const newThreading = [...formik.values.threading];
      newThreading.splice(id, 1);
      formik.setFieldValue("threading", newThreading);

      const newThreadingPrice = [...formik.values.threadingPrice];
      newThreadingPrice.splice(id, 1);
      formik.setFieldValue("threadingPrice", newThreadingPrice);
    }

    if (itemId === 2) {
      const newWaxing = [...formik.values.waxing];
      newWaxing.splice(id, 1);
      formik.setFieldValue("waxing", newWaxing);

      const newWaxingPrice = [...formik.values.waxingPrice];
      newWaxingPrice.splice(id, 1);
      formik.setFieldValue("waxingPrice", newWaxingPrice);
    }

    if (itemId === 3) {
      const newEyebrows = [...formik.values.eyebrows];
      newEyebrows.splice(id, 1);
      formik.setFieldValue("eyebrows", newEyebrows);

      const newEyebrowsPrice = [...formik.values.eyebrowsPrice];
      newEyebrowsPrice.splice(id, 1);
      formik.setFieldValue("eyebrowsPrice", newEyebrowsPrice);
    }

    if (itemId === 4) {
      const newEyeLashes = [...formik.values.eyeLashes];
      newEyeLashes.splice(id, 1);
      formik.setFieldValue("eyeLashes", newEyeLashes);

      const newEyeLashesPrice = [...formik.values.eyeLashesPrice];
      newEyeLashesPrice.splice(id, 1);
      formik.setFieldValue("eyeLashesPrice", newEyeLashesPrice);
    }
  };

  const handleSelect = (id: number, newValue: any, itemId: number) => {
    if (itemId === 1) {
      const newThreading = [...formik.values.threading];
      newThreading[id] = newValue;
      formik.setFieldValue("threading", newThreading);
    }

    if (itemId === 2) {
      const newWaxing = [...formik.values.waxing];
      newWaxing[id] = newValue;
      formik.setFieldValue("waxing", newWaxing);
    }

    if (itemId === 3) {
      const newEyebrows = [...formik.values.eyebrows];
      newEyebrows[id] = newValue;
      formik.setFieldValue("eyebrows", newEyebrows);
    }

    if (itemId === 4) {
      const newEyeLashes = [...formik.values.eyeLashes];
      newEyeLashes[id] = newValue;
      formik.setFieldValue("eyeLashes", newEyeLashes);
    }
  };

  const handleChange = (id: number, newValue: any, itemId: number) => {
    if (itemId === 1) {
      const newThreadingPrice = [...formik.values.threadingPrice];
      newThreadingPrice[id] = newValue;
      formik.setFieldValue("threadingPrice", newThreadingPrice);
    }

    if (itemId === 2) {
      const newWaxingPrice = [...formik.values.waxingPrice];
      newWaxingPrice[id] = newValue;
      formik.setFieldValue("waxingPrice", newWaxingPrice);
    }

    if (itemId === 3) {
      const newEyebrowsPrice = [...formik.values.eyebrowsPrice];
      newEyebrowsPrice[id] = newValue;
      formik.setFieldValue("eyebrowsPrice", newEyebrowsPrice);
    }

    if (itemId === 4) {
      const newEyeLashesPrice = [...formik.values.eyeLashesPrice];
      newEyeLashesPrice[id] = newValue;
      formik.setFieldValue("eyeLashesPrice", newEyeLashesPrice);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Description</b>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Price</b>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Regular Hair Cut</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="regularHairCutPrice"
                value={formik.values.regularHairCutPrice}
                onChange={formik.handleChange}
              />
              {formik.touched.regularHairCutPrice &&
                formik.errors.regularHairCutPrice && (
                  <Typography className="error">
                    {formik.errors.regularHairCutPrice}
                  </Typography>
                )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Facial</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="facialPrice"
                value={formik.values.facialPrice}
                onChange={formik.handleChange}
              />
              {formik.touched.facialPrice && formik.errors.facialPrice && (
                <Typography className="error">
                  {formik.errors.facialPrice}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        {formik.values.threading.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
              >
                <Typography component={"span"}>Threading</Typography>
                <Box>
                  <Select
                    size="small"
                    name="threading"
                    value={formik.values.threading[index]}
                    onChange={(e: any) =>
                      handleSelect(index, e.target.value, 1)
                    }
                    onBlur={formik.handleBlur}
                    sx={{ width: 150, height: "48px" }}
                    displayEmpty
                    defaultValue=""
                  >
                    <MenuItem disabled value="">
                      Please select
                    </MenuItem>
                    <MenuItem value="Eye Brows">Eye Brows</MenuItem>
                    <MenuItem value="Full Face">Full Face</MenuItem>
                    <MenuItem value="Chin">Chin</MenuItem>
                    <MenuItem value="Upper Lips">Upper Lips</MenuItem>
                  </Select>
                  {formik.touched.threading && formik.errors.threading && (
                    <Typography className="error">
                      {formik.errors.threading}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography component={"span"}>$</Typography>
                <Box>
                  <Input
                    type="text"
                    name="threadingPrice"
                    value={formik.values.threadingPrice[index]}
                    onChange={(e: any) =>
                      handleChange(index, e.target.value, 1)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.threadingPrice &&
                    formik.errors.threadingPrice && (
                      <Typography className="error">
                        {formik.errors.threadingPrice}
                      </Typography>
                    )}
                </Box>
                {index === 0 ? (
                  <IconButton
                    onClick={
                      formik.values.threading.length < 4
                        ? () => handleAddButton(1)
                        : undefined
                    }
                    sx={{
                      backgroundColor: "#11b4f5",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleRemoveButton(index, 1)}
                    sx={{
                      backgroundColor: "red",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "red",
                        color: "#FFF",
                      },
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </React.Fragment>
        ))}

        {formik.values.waxing.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
              >
                <Typography component={"span"}>Waxing</Typography>
                <Box>
                  <Select
                    size="small"
                    name="waxing"
                    value={formik.values.waxing[index]}
                    onChange={(e: any) =>
                      handleSelect(index, e.target.value, 2)
                    }
                    onBlur={formik.handleBlur}
                    sx={{ width: 150, height: "48px" }}
                    displayEmpty
                    defaultValue=""
                  >
                    <MenuItem disabled value="">
                      Please select
                    </MenuItem>
                    <MenuItem value="Face">Face</MenuItem>
                    <MenuItem value="Full Arms">Full Arms</MenuItem>
                    <MenuItem value="Full Legs">Full Legs</MenuItem>
                    <MenuItem value="Full Body">Full Body</MenuItem>
                  </Select>
                  {formik.touched.waxing && formik.errors.waxing && (
                    <Typography className="error">
                      {formik.errors.waxing}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography component={"span"}>$</Typography>
                <Box>
                  <Input
                    type="text"
                    name="waxingPrice"
                    value={formik.values.waxingPrice[index]}
                    onChange={(e: any) =>
                      handleChange(index, e.target.value, 2)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.waxingPrice && formik.errors.waxingPrice && (
                    <Typography className="error">
                      {formik.errors.waxingPrice}
                    </Typography>
                  )}
                </Box>
                {index === 0 ? (
                  <IconButton
                    onClick={
                      formik.values.waxing.length < 4
                        ? () => handleAddButton(2)
                        : undefined
                    }
                    sx={{
                      backgroundColor: "#11b4f5",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleRemoveButton(index, 2)}
                    sx={{
                      backgroundColor: "red",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "red",
                        color: "#FFF",
                      },
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </React.Fragment>
        ))}

        {formik.values.eyebrows.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
              >
                <Typography component={"span"}>Eyebrows</Typography>
                <Box>
                  <Select
                    size="small"
                    name="eyebrows"
                    value={formik.values.eyebrows[index]}
                    onChange={(e: any) =>
                      handleSelect(index, e.target.value, 3)
                    }
                    onBlur={formik.handleBlur}
                    sx={{ width: 150, height: "48px" }}
                    displayEmpty
                    defaultValue=""
                  >
                    <MenuItem disabled value="">
                      Please select
                    </MenuItem>
                    <MenuItem value="Lamination + Shaping">
                      Lamination + Shaping
                    </MenuItem>
                    <MenuItem value="Lamination + tint + shape">
                      Lamination + tint + shape
                    </MenuItem>
                    <MenuItem value="Tint + Shape">Tint + Shape</MenuItem>
                    <MenuItem value="Shape with wax">Shape with wax</MenuItem>
                  </Select>
                  {formik.touched.eyebrows && formik.errors.eyebrows && (
                    <Typography className="error">
                      {formik.errors.eyebrows}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography component={"span"}>$</Typography>
                <Box>
                  <Input
                    type="text"
                    name="eyebrowsPrice"
                    value={formik.values.eyebrowsPrice[index]}
                    onChange={(e: any) =>
                      handleChange(index, e.target.value, 3)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.eyebrowsPrice &&
                    formik.errors.eyebrowsPrice && (
                      <Typography className="error">
                        {formik.errors.eyebrowsPrice}
                      </Typography>
                    )}
                </Box>
                {index === 0 ? (
                  <IconButton
                    onClick={
                      formik.values.eyebrows.length < 4
                        ? () => handleAddButton(3)
                        : undefined
                    }
                    sx={{
                      backgroundColor: "#11b4f5",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleRemoveButton(index, 3)}
                    sx={{
                      backgroundColor: "red",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "red",
                        color: "#FFF",
                      },
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </React.Fragment>
        ))}

        {formik.values.eyeLashes.map((item: any, index: number) => (
          <React.Fragment key={item.id}>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
              >
                <Typography component={"span"}>Eye Lashes</Typography>
                <Box>
                  <Select
                    size="small"
                    name="eyeLashes"
                    value={formik.values.eyeLashes[index]}
                    onChange={(e: any) =>
                      handleSelect(index, e.target.value, 4)
                    }
                    onBlur={formik.handleBlur}
                    sx={{ width: 150, height: "48px" }}
                    displayEmpty
                    defaultValue=""
                  >
                    <MenuItem disabled value="">
                      Please select
                    </MenuItem>
                    <MenuItem value="Lift + Tint">Lift + Tint</MenuItem>
                    <MenuItem value="lift">Lift</MenuItem>
                    <MenuItem value="tint">Tint</MenuItem>
                  </Select>
                  {formik.touched.eyeLashes && formik.errors.eyeLashes && (
                    <Typography className="error">
                      {formik.errors.eyeLashes}
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} lg={6}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography component={"span"}>$</Typography>
                <Box>
                  <Input
                    type="text"
                    name="eyeLashesPrice"
                    value={formik.values.eyeLashesPrice[index]}
                    onChange={(e: any) =>
                      handleChange(index, e.target.value, 4)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.eyeLashesPrice &&
                    formik.errors.eyeLashesPrice && (
                      <Typography className="error">
                        {formik.errors.eyeLashesPrice}
                      </Typography>
                    )}
                </Box>
                {index === 0 ? (
                  <IconButton
                    onClick={
                      formik.values.eyeLashes.length < 3
                        ? () => handleAddButton(4)
                        : undefined
                    }
                    sx={{
                      backgroundColor: "#11b4f5",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => handleRemoveButton(index, 4)}
                    sx={{
                      backgroundColor: "red",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "red",
                        color: "#FFF",
                      },
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Stack>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default InHomeSalonWomen;
