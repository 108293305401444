import { Stack } from "@mui/system";
import { Box, Button, Typography } from "@mui/material";
import ListingTable from "../../../component/ListingTable";
import { useEffect, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import Services from "../../../../../api/services";
import Swal from "sweetalert2";
import EditRefundAndCredit from "./EditRefundAndCredit";

interface Column {
  id:
    | "serviceName"
    | "serviceProvider"
    | "serviceDateTime"
    | "serviceAmount"
    | "refund"
    | "status"
    | "action";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [
  { id: "serviceName", label: "Service Name", minWidth: 170 },
  { id: "serviceProvider", label: "Service Provider", minWidth: 200 },
  { id: "serviceDateTime", label: "Service DateTime", minWidth: 120 },
  { id: "serviceAmount", label: "Service Amount", minWidth: 80 },
  { id: "refund", label: "Refund Amount", minWidth: 70 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "action", label: "Action", minWidth: 170 },
];

interface Data {
  id: number;
  serviceName: string;
  serviceProvider: string;
  serviceDateTime: string;
  serviceAmount: string;
  refund: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

function createData(
  id: number,
  serviceName: string,
  serviceProvider: string,
  serviceDateTime: string,
  serviceAmount: string,
  refund: string,
  status: React.ReactNode,
  action: React.ReactNode
): Data {
  return {
    id,
    serviceName,
    serviceProvider,
    serviceDateTime,
    serviceAmount,
    refund,
    status,
    action,
  };
}

const AllRefundAndCredits = () => {
  const [bookingsData, setBookingsData] = useState([{}]);
  const [editService, setEditService] = useState(false);

  const handleListService = async () => {
    await getRefundAndCredit();
    setEditService(false);
  };

  const getRefundAndCredit = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.admin.getRefundAndCreditService(
          options
        );
        setBookingsData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getRefundAndCredit();
  }, []);

  const handleEditBooking = async (id: number) => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await Services.admin.viewBookingDataById(id, options);
        if (response.status === 200) {
          const data = response?.data?.data;
          setBookingsData(data);
        }
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors) {
        Swal.fire({
          title: "Error!",
          text: `${error.response.data.errors}`,
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "An unexpected error occurred.",
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      }
      console.error("Error during POST request:", error);
    }

    setEditService(true);
  };

  const rows = bookingsData.map((item: any) => {
    const rowData = {
      id: item.id,
      serviceName: item.service_name,
      serviceProvider: item.service_provider,
      serviceDateTime: item.service_date_time,
      totalAmount: item.total_amount,
      adjustmentAmount: item.adjustment_amount,
      status: item.status,
    };

    return createData(
      rowData.id,
      rowData.serviceName,
      rowData.serviceProvider,
      rowData.serviceDateTime,
      rowData.totalAmount,
      `${
        rowData.adjustmentAmount > 0
          ? `-${rowData.adjustmentAmount}`
          : rowData.adjustmentAmount
      }`,
      <Box
        component="span"
        sx={{
          color:
            rowData.status === "pending"
              ? "#11b4f5"
              : rowData.status === "accepted"
              ? "#008000"
              : rowData.status === "completed"
              ? "#008000"
              : "#FF0000",
        }}
      >
        {rowData.status}
      </Box>,
      <Stack direction={"row"} spacing={1}>
        <Button
          variant="contained"
          onClick={() => handleEditBooking(item.id)}
          size="small"
        >
          Edit
        </Button>
      </Stack>
    );
  });

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent="space-between"
        display="flex"
        marginBottom={2}
        alignItems={"center"}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "24px",
            mb: 1,
            "@media(max-width: 768px)": {
              fontSize: "18px",
            },
          }}
        >
          {editService ? "Edit Refund And Credit" : "Refund And Credit"}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            mb: 1,
            alignItems: "center",
            display: "flex",
          }}
        >
          {editService && (
            <Button variant="contained" onClick={handleListService}>
              <ListIcon sx={{ marginRight: "5px" }} />
              List Refund And Credit
            </Button>
          )}
        </Typography>
      </Stack>
      {editService ? (
        <EditRefundAndCredit
          setBookingsData={setBookingsData}
          bookingsData={bookingsData}
          setEditService={setEditService}
        />
      ) : (
        <ListingTable
          columns={columns}
          rows={rows}
          shouldShowPagination={true}
        />
      )}
    </>
  );
};

export default AllRefundAndCredits;
