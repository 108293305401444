import { Grid } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import { IMAGES } from "../../assets/images";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "#11b4f512",
        color: "#000",
        py: 2,
        fontFamily: "'Open Sans', sans-serif",
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} lg={4}>
            <img alt="Logo" src={IMAGES.Logo} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <Stack
              direction={{ xs: "column", lg: "row" }}
              spacing={2}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box sx={{ lineHeight: "28px" }}>
                Copyright @{new Date().getFullYear()}. All Rights Reserved by
                <b> 15620767 Canada Inc.</b>
              </Box>
              <Box
                sx={{
                  "& a": {
                    color: "#000",
                    textDecoration: "#000",
                    display: "inline-block",
                    px: 2,
                  },
                }}
              >
                <Link to="/term-and-conditions">Terms of Use</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
