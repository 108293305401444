import { Button, FormGroup, FormLabel, Typography } from "@mui/material";
import React, { useState } from "react";
import Input from "../../../../component/input";
import Services from "../../../../api/services";
import Swal from "sweetalert2";

interface CompanyRegistrationProps {
  companyName: string;
  establishedYear: string;
  registrationNumber: string;
}

interface companyProps {
  handleClose: (value: boolean) => void;
}

const CompanyRegistration: React.FC<companyProps> = ({ handleClose }) => {
  const [errors, setErrors] = useState<Partial<CompanyRegistrationProps>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<CompanyRegistrationProps>({
    companyName: "",
    establishedYear: "",
    registrationNumber: "",
  });

  const validateForm = () => {
    const newErrors: Partial<CompanyRegistrationProps> = {};

    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company name is required";
    }

    if (!formData.establishedYear.trim()) {
      newErrors.establishedYear = "Established Year is required";
    }

    if (!formData.registrationNumber.trim()) {
      newErrors.registrationNumber = "Registration Number is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitted(true);
      const data = {
        company_name: formData.companyName,
        established_year: formData.establishedYear,
        registration_number: formData.registrationNumber,
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateCompanyRegService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "Company registration has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormGroup sx={{ my: 2 }}>
          <FormLabel sx={{ mb: 1 }}>Company Name</FormLabel>
          <Input
            type="text"
            name="companyName"
            placeholder="Company Name"
            value={formData.companyName}
            onChange={handleInputChange}
          />
          {errors.companyName && (
            <Typography color="error">{errors.companyName}</Typography>
          )}
        </FormGroup>
        <FormGroup sx={{ my: 2 }}>
          <FormLabel sx={{ mb: 1 }}>Established Year</FormLabel>
          <Input
            type="number"
            name="establishedYear"
            placeholder="Established Year"
            value={formData.establishedYear}
            onChange={handleInputChange}
          />
          {errors.establishedYear && (
            <Typography color="error">{errors.establishedYear}</Typography>
          )}
        </FormGroup>
        <FormGroup sx={{ my: 2 }}>
          <FormLabel sx={{ mb: 1 }}>Registration Number</FormLabel>
          <Input
            type="text"
            name="registrationNumber"
            placeholder="Registration Number"
            value={formData.registrationNumber}
            onChange={handleInputChange}
          />
          {errors.registrationNumber && (
            <Typography color="error">{errors.registrationNumber}</Typography>
          )}
        </FormGroup>
        <Button type="submit" variant="contained" disabled={isSubmitted}>
          {isSubmitted ? (
            <span style={{ color: "#fff" }}>Submitting...</span>
          ) : (
            "Submit"
          )}
        </Button>
      </form>
    </>
  );
};

export default CompanyRegistration;
