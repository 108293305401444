import HomeDictionary from "./homeDictionary";
import CustomerDictionary from "./customerDictionary";
import ProviderDictionary from "./providerDictionary";
import AuthDictionary from "./authDictionary";
import AdminDictionary from "./adminDictionary";

const Dictionary = {
  home: HomeDictionary,
  customer: CustomerDictionary,
  provider: ProviderDictionary,
  auth: AuthDictionary,
  admin: AdminDictionary,
};

export default Dictionary;
