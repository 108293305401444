import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListingTable from "../component/ListingTable";
import { Box, Stack } from "@mui/system";
import AddPayment from "./add-payment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListIcon from "@mui/icons-material/List";
import Swal from "sweetalert2";
import Services from "../../../api/services";

interface Column {
  id: "name" | "cardNumber" | "expiryDate" | "cvv" | "action";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "cardNumber", label: "Card Number", minWidth: 150 },
  { id: "expiryDate", label: "Expiry Date", minWidth: 170 },
  { id: "cvv", label: "CVV", minWidth: 120 },
  { id: "action", label: "Action", minWidth: 170 },
];

interface Data {
  id: number;
  name: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  action: React.ReactNode;
}

function createData(
  id: number,
  name: string,
  cardNumber: string,
  expiryDate: string,
  cvv: string,
  action: React.ReactNode
): Data {
  return { id, name, cardNumber, expiryDate, cvv, action };
}

const PaymentMethod: React.FC = () => {
  const [addPayment, setAddPayment] = useState(false);
  const [editPayment, setEditPayment] = useState(false);
  const [cardData, setCardData] = useState([{}]);

  const getAllPaymentMethods = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.customer.getAllPaymentMethodsService(
          options
        );
        setCardData(response.data.payment_methods);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllPaymentMethods();
  }, []);

  const handleAddPayment = () => {
    setCardData([{}]);
    setAddPayment(true);
  };

  const handleListPayment = async () => {
    await getAllPaymentMethods();
    setAddPayment(false);
    setEditPayment(false);
  };

  const handleEditPayment = async (id: number) => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await Services.customer.viewPaymentMethodService(
          id,
          options
        );
        if (response.status === 200) {
          const data = response?.data?.payment_methods;
          setCardData(data);
        }
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors) {
        Swal.fire({
          title: "Error!",
          text: `${error.response.data.errors}`,
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "An unexpected error occurred.",
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      }
      console.error("Error during POST request:", error);
    }

    setAddPayment(true);
    setEditPayment(true);
  };

  const handleRemovePayment = (id: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this payment method!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "#11b4f5",
      cancelButtonColor: "#ef5350",
      customClass: {
        cancelButton: "swal-cancel-button-class",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const tokenString = localStorage.getItem("access_token");
          if (tokenString !== null) {
            const token = JSON.parse(tokenString);
            const options = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

            const response = await Services.customer.deletePaymentMethodService(
              id,
              options
            );
            if (response.status === 200) {
              const result =
                await Services.customer.getAllPaymentMethodsService(options);
              setCardData(result.data.payment_methods);
              Swal.fire(
                "Deleted!",
                "Payment method has been deleted.",
                "success"
              );
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Payment method is safe :)", "error");
      }
    });
  };

  const rows = cardData.map((item: any, index: number) => {
    const rowData = {
      index: index + 1, // Assuming the index starts from 1
      name: item.card_holder_name, // Assuming 'name' is a property in your cardData item
      cardNumber: item.card_number, // Assuming 'cardNumber' is a property in your cardData item
      expiryDate: `${item.expiry_month}/${item.expiry_year}`, // Assuming 'expiryDate' is a property in your cardData item
      cvv: item.cvv, // Assuming 'cvv' is a property in your cardData item
    };

    return createData(
      rowData.index,
      rowData.name,
      rowData.cardNumber,
      rowData.expiryDate,
      rowData.cvv,
      <Stack direction={"row"} spacing={1}>
        <Button
          variant="contained"
          onClick={() => handleEditPayment(item.id)} // Assuming 'id' is a property in your cardData item
          size="small"
        >
          Edit
        </Button>
        <Button
          onClick={() => handleRemovePayment(item.id)} // Assuming 'id' is a property in your cardData item
          sx={{
            textDecoration: "none",
            background: "#ef5350",
            color: "#fff",
            borderRadius: "30px",
            textTransform: "uppercase",
            padding: "6px 16px",
            "&:hover": {
              background: "#ef5350",
              color: "#fff",
            },
          }}
        >
          Remove
        </Button>
      </Stack>
    );
  });

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        marginBottom={2}
        useFlexGap
        flexWrap="wrap"
      >
        <Typography variant="h2" sx={{ fontSize: "24px", mb: 1 }}>
          {addPayment
            ? editPayment
              ? "Edit Payment Method"
              : "Add Payment Method"
            : "Payment Methods"}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            mb: 1,
            alignItems: "center",
            display: "flex",
          }}
        >
          {rows.length !== 0 && (
            <>
              {addPayment ? (
                <Button variant="contained" onClick={handleListPayment}>
                  <ListIcon sx={{ marginRight: "5px" }} />
                  Payment Methods
                </Button>
              ) : (
                <Button variant="contained" onClick={handleAddPayment}>
                  <AddCircleOutlineIcon sx={{ marginRight: "5px" }} />
                  Add Payment Method
                </Button>
              )}
            </>
          )}
        </Typography>
      </Stack>
      {rows.length > 0 && !addPayment ? (
        <ListingTable columns={columns} rows={rows} />
      ) : (
        <AddPayment
          setAddPayment={handleListPayment}
          editPayment={editPayment}
          cardData={cardData}
          setCardData={setCardData}
        />
      )}
    </Box>
  );
};

export default PaymentMethod;
