import React, { Suspense } from "react";
import ThemeCustomization from "./themes";
import { Route, Routes } from "react-router-dom";
import MainRoute from "./utils/routes";
import NotFound from "./NotFound";
import { RotatingLines } from "react-loader-spinner";
import { Box } from "@mui/material";
import { GlobalStateProvider } from "./GlobalState"; 

const renderChild = (route: any, index: number) => {
  return (
    <Route
      path={route.path}
      element={
        <>
          <Suspense
            fallback={
              <Box
                sx={{
                  height: "calc(100vh - 200px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  visible={true}
                  width="50"
                  strokeWidth="2"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                />
              </Box>
            }
          >
            {route.element}
          </Suspense>
        </>
      }
      key={`${index}-${route.path}`}
    >
      {route.children &&
        route.children.map((child_route: any, index: number) =>
          renderChild(child_route, index)
        )}
    </Route>
  );
};

function App() {
  return (
    <ThemeCustomization>
      <GlobalStateProvider> 
        <Routes>
          {MainRoute.map((route, index) => renderChild(route, index))}
          <Route path="/notfound" element={<NotFound />} />
        </Routes>
      </GlobalStateProvider>
    </ThemeCustomization>
  );
}

export default App;
