import { useEffect, useState } from "react";
import MyProfileComponent from "../component/my-profile";
import Services from "../../../api/services";
import CustomerFeedback from "./customer-feedback";
import Drawer from "../component/Drawer";

interface Service {
  id: number;
  service_name: string;
  service_provider: string;
  customer_feedback_status: string;
}

const MyProfile = () => {
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const [serviceData, setServiceData] = useState<Service[]>([]);
  const getFeedbackStatus = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response =
          await Services.customer.getCustomerFeedbackStatusService(options);

        const feedbackEntries = response.data.data.filter(
          (item: any) => item.customer_feedback_status === "1"
        );

        if (feedbackEntries.length > 0) {
          setFeedbackStatus(true);
          setServiceData(feedbackEntries);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getFeedbackStatus();

    window.scrollTo(0, 0);
  }, []);

  const handleComplete = () => {
    setFeedbackStatus(!feedbackStatus);
  };

  return (
    <>
      {feedbackStatus && (
        <Drawer isOpen={feedbackStatus} title="Customer Feedback Form">
          <CustomerFeedback
            serviceData={serviceData}
            setFeedbackStatus={setFeedbackStatus}
            setServiceData={setServiceData}
            handleClose={handleComplete}
          />
        </Drawer>
      )}
      <MyProfileComponent />
    </>
  );
};
export default MyProfile;
