import {
  Button,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Input from "../../../../../component/input";
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Services from "../../../../../api/services";
import Swal from "sweetalert2";
import { StyledProvider } from "../../service/style";

interface formDataProps {
  id?: number;
  sliderName: string;
  status: string;
}

interface AddServiceProps {
  setBannerData: any;
  editBanner?: boolean;
  bannerData: any;
  setAddBanner: (value: boolean) => void;
}

const AddBanner: React.FC<AddServiceProps> = ({
  setBannerData,
  setAddBanner,
  editBanner = false,
  bannerData,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formik = useFormik<formDataProps>({
    initialValues: {
      id: bannerData[0]?.id || 0,
      sliderName: bannerData[0]?.image || "",
      status: bannerData[0]?.status,
    },
    validationSchema: Yup.object({
      sliderName: Yup.string().required("Name is required"),
      status: Yup.mixed().required("Status is required"),
    }),
    onSubmit: async (values) => {
      console.log(values);

      setIsSubmitted(true);

      var bodyFormData = new FormData();

      bodyFormData.append(
        "id",
        values.id !== undefined ? String(values.id) : ""
      );
      bodyFormData.append("image", values.sliderName);
      bodyFormData.append("status", values.status);

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };

          const response = (await bannerData[0]?.id)
            ? Services.admin.updateBannerService(bodyFormData, options)
            : Services.admin.addBannerService(bodyFormData, options);

          if ((await response).status === 200) {
            const responseData = await Services.admin.getBannerService(options);
            setBannerData(responseData.data.data);
            Swal.fire({
              title: bannerData[0]?.id ? "Updated!" : "Added!",
              text: bannerData[0]?.id
                ? "Updated banner successfully."
                : "Add banner successfully.",
              icon: "success",
            });
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
      setAddBanner(false);
    },
  });

  const handleInputChange = (event: any) => {
    const { name, value, files } = event.target;

    if (files && files.length > 0) {
      const selectedFile = files[0];
      formik.setFieldValue(name, selectedFile);
    } else {
      formik.setFieldValue(name, value);
    }
  };

  return (
    <Box sx={{ pb: 2 }} component="form" onSubmit={formik.handleSubmit}>
      <StyledProvider>
        <FormGroup>
          <FormLabel>Banner Picture To Display</FormLabel>
          <Input type="file" name="sliderName" onChange={handleInputChange} />
          {formik.touched.sliderName && formik.errors.sliderName && (
            <Typography className="error">
              {formik.errors.sliderName.toString()}
            </Typography>
          )}
          {bannerData[0]?.image && (
            <Box
              sx={{
                width: "150px",
                borderRadius: "5px",
                padding: "5px",
                border: "1px solid #ccc",
                mt: 2,
                position: "relative",
              }}
            >
              <img
                src={`${process.env.REACT_APP_BACKEND_BASE_URL}upload/${bannerData[0]?.image}`}
                alt="Preview"
                style={{ maxWidth: "100%", display: "inherit" }}
              />
            </Box>
          )}
        </FormGroup>

        <FormGroup>
          <FormLabel>Status</FormLabel>
          <Select
            name="status"
            value={formik.values.status}
            onChange={formik.handleChange}
            displayEmpty
            defaultValue=""
          >
            <MenuItem value="" disabled>
              Please select
            </MenuItem>
            <MenuItem value="1">Active</MenuItem>
            <MenuItem value="0">Deactive</MenuItem>
          </Select>
          {formik.touched.status && formik.errors.status && (
            <Typography className="error">
              {formik.errors.status as React.ReactNode}
            </Typography>
          )}
        </FormGroup>

        <Button type="submit" variant="contained" disabled={isSubmitted}>
          {isSubmitted ? (
            <span style={{ color: "#fff" }}>Submitting...</span>
          ) : editBanner ? (
            "Update Banner"
          ) : (
            "Add Banner"
          )}
        </Button>
      </StyledProvider>
    </Box>
  );
};

export default AddBanner;
