import { Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Input from "../../../../../component/input";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface MobileCarDetailingProps {
  formik: any;
}

const MobileCarDetailing: React.FC<MobileCarDetailingProps> = ({ formik }) => {
  const handleIconClick = () => {
    formik.setFieldValue("typeOfCar", [
      ...formik.values.typeOfCar,
      "",
    ]);
    formik.setFieldValue("typeOfCarPrice", [
      ...formik.values.typeOfCarPrice,
      "",
    ]);
  };

  const handleRemoveClick = (id: number) => {
    const newTypeOfCar = [...formik.values.typeOfCar];
    newTypeOfCar.splice(id, 1);
    const newNoOfCrDriveWayPrice = [...formik.values.typeOfCarPrice];
    newNoOfCrDriveWayPrice.splice(id, 1);
    formik.setFieldValue("typeOfCar", newTypeOfCar);
    formik.setFieldValue("typeOfCarPrice", newNoOfCrDriveWayPrice);
  };

  const handlePriceChange = (itemId: number, newValue: any) => {
    const newPrices = [...formik.values.typeOfCarPrice];
    newPrices[itemId] = newValue;
    formik.setFieldValue("typeOfCarPrice", newPrices);
  };

  const handleCarTypeChange = (itemId: number, newValue: any) => {
    const newTypeOfCar = [...formik.values.typeOfCar];
    newTypeOfCar[itemId] = newValue;
    formik.setFieldValue("typeOfCar", newTypeOfCar);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Description</b>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <b>Price</b>
        </Grid>

        {Array.isArray(formik.values.typeOfCar) &&
          formik.values.typeOfCar.map((item: any, index: number) => (
            <React.Fragment key={item.id}>
              <Grid item xs={6} sm={6} lg={6}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  spacing={2}
                >
                  <Typography component={"span"}>Type of car</Typography>
                  <Box>
                    <Select
                      size="small"
                      name="typeOfCar"
                      value={formik.values.typeOfCar[index]}
                      onChange={(e) =>
                        handleCarTypeChange(index, e.target.value)
                      }
                      sx={{ width: 150, height: "48px" }}
                      displayEmpty
                      defaultValue=""
                    >
                      <MenuItem disabled value="">
                        Please select
                      </MenuItem>
                      <MenuItem value="Hatch Back">Hatch Back</MenuItem>
                      <MenuItem value="Sedan">Sedan</MenuItem>
                      <MenuItem value="SUV">SUV</MenuItem>
                      <MenuItem value="7-Seater">7-Seater</MenuItem>
                    </Select>
                    {formik.touched.typeOfCar &&
                      formik.errors.typeOfCar && (
                        <Typography className="error">
                          {formik.errors.typeOfCar}
                        </Typography>
                      )}
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={6} lg={6}>
                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                  <Typography component={"span"}>$</Typography>
                  <Box>
                    <Input
                      type="text"
                      name="typeOfCarPrice"
                      value={formik.values.typeOfCarPrice[index]}
                      onChange={(e: any) =>
                        handlePriceChange(index, e.target.value)
                      }
                    />
                    {formik.touched.typeOfCarPrice &&
                      formik.errors.typeOfCarPrice && (
                        <Typography className="error">
                          {formik.errors.typeOfCarPrice}
                        </Typography>
                      )}
                  </Box>
                  {index === 0 ? (
                    <IconButton
                      onClick={
                        formik.values.typeOfCar.length < 4
                          ? handleIconClick
                          : undefined
                      }
                      sx={{
                        backgroundColor: "#11b4f5",
                        color: "#FFF",
                        "&:hover": {
                          backgroundColor: "#11b4f5",
                          color: "#FFF",
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleRemoveClick(index)}
                      sx={{
                        backgroundColor: "red",
                        color: "#FFF",
                        "&:hover": {
                          backgroundColor: "red",
                          color: "#FFF",
                        },
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            </React.Fragment>
          ))}

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Leather Seats</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="leatherSeatsPrice"
                value={formik.values.leatherSeatsPrice}
                onChange={formik.handleChange}
              />
              {formik.touched.leatherSeatsPrice && formik.errors.leatherSeatsPrice && (
                <Typography className="error">
                  {formik.errors.leatherSeatsPrice}
                </Typography>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={6} sm={6} lg={6}>
          <Typography component={"span"}>Leather Interior</Typography>
        </Grid>
        <Grid item xs={6} sm={6} lg={6}>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography component={"span"}>$</Typography>
            <Box>
              <Input
                type="text"
                name="leatherInteriorPrice"
                value={formik.values.leatherInteriorPrice}
                onChange={formik.handleChange}
              />
              {formik.touched.leatherInteriorPrice &&
                formik.errors.leatherInteriorPrice && (
                  <Typography className="error">
                    {formik.errors.leatherInteriorPrice}
                  </Typography>
                )}
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MobileCarDetailing;
