import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerProps {
  dateRange: [Date | null, Date | null];
  setDateRange: (value: [Date | null, Date | null]) => void;
}

const DateRangePicker: React.FC<DatePickerProps> = ({
  dateRange,
  setDateRange,
}) => {
  const [startDate, endDate] = dateRange;

  return (
    <DatePicker
      selected={startDate}
      onChange={(update: [Date | null, Date | null]) => {
        setDateRange(update);
      }}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      placeholderText="Start date - End date"
    />
  );
};

export default DateRangePicker;
