import {
  Button,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Input from "../../../../component/input";
import Swal from "sweetalert2";
import Services from "../../../../api/services";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";

interface workEligibilityProps {
  WorkDocFront: any;
  WorkDocBack: any;
  documentType: any;
}

interface props {
  handleClose: (value: boolean) => void;
}

const WorkEligibility: React.FC<props> = ({ handleClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<Partial<workEligibilityProps>>({});
  const [formData, setFormData] = useState<workEligibilityProps>({
    WorkDocFront: "",
    WorkDocBack: "",
    documentType: "",
  });

  const [WorkDocumentFront, setWorkDocumentFront] = useState<string | null>(
    null
  );
  const [WorkDocumentBack, setWorkDocumentBack] = useState<string | null>(null);

  const validateForm = () => {
    const newErrors: Partial<workEligibilityProps> = {};

    if (!formData.WorkDocFront) {
      newErrors.WorkDocFront = "Work Eligibility Front is required";
    }

    if (!formData.WorkDocBack) {
      newErrors.WorkDocBack = "Work Eligibility Back is required";
    }

    if (!formData.documentType.trim()) {
      newErrors.documentType = "Document Type is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, files } = e.target as HTMLInputElement; // Explicit casting to HTMLInputElement
    if (files && files.length > 0) {
      // Handle file selection
      const selectedFile = files[0];
      setFormData((prevData) => ({ ...prevData, [name]: selectedFile }));
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          if (name === "WorkDocFront") {
            setWorkDocumentFront(event.target.result as string);
          } else {
            setWorkDocumentBack(event.target.result as string);
          }
        }
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Handle other input types
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const removeImage = (imageType: "front" | "back") => {
    if (imageType === "front") {
      setWorkDocumentFront(null);
      const frontInput = document.querySelector(
        'input[name="WorkDocFront"]'
      ) as HTMLInputElement;
      if (frontInput) {
        frontInput.value = ""; // Clear the input value
      }
    } else {
      setWorkDocumentBack(null);
      const backInput = document.querySelector(
        'input[name="WorkDocBack"]'
      ) as HTMLInputElement;
      if (backInput) {
        backInput.value = ""; // Clear the input value
      }
    }
  };

  const handleSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitted(true);
      var data = new FormData();
      data.append("WorkDocFront", formData.WorkDocFront);
      data.append("WorkDocBack", formData.WorkDocBack);
      data.append("document_type", formData.documentType);

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.provider.updateWorkEligibilityService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Added!",
              text: "Work eligibility has been added.",
              icon: "success",
            });
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={12} lg={6}>
            <FormGroup sx={{ my: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Upload photo Front</FormLabel>
              <Input
                type="file"
                name="WorkDocFront"
                // value={formData.WorkDocFront}
                onChange={handleInputChange}
              />
              {WorkDocumentFront && (
                <Box
                  sx={{
                    width: "150px",
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    mt: 2,
                    position: "relative",
                  }}
                >
                  <IconButton
                    disableRipple={true}
                    sx={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      zIndex: 10,
                      width: "20px",
                      height: "20px",
                      background: "#11b4f5",
                      color: "#fff",
                    }}
                    onClick={() => removeImage("front")}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <img
                    src={WorkDocumentFront}
                    alt="Preview"
                    style={{ maxWidth: "100%", display: "inherit" }}
                  />
                </Box>
              )}
              {errors.WorkDocFront && (
                <Typography color="error">{errors.WorkDocFront}</Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormGroup sx={{ my: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Upload Photo Back</FormLabel>
              <Input
                type="file"
                name="WorkDocBack"
                // value={formData.WorkDocBack}
                onChange={handleInputChange}
              />
              {WorkDocumentBack && (
                <Box
                  sx={{
                    width: "150px",
                    borderRadius: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    mt: 2,
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={() => removeImage("back")}
                    disableRipple={true}
                    sx={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      zIndex: 10,
                      width: "20px",
                      height: "20px",
                      background: "#11b4f5",
                      color: "#fff",
                    }}
                  >
                    <CloseIcon sx={{ fontSize: "16px" }} />
                  </IconButton>
                  <img
                    src={WorkDocumentBack}
                    alt="Preview"
                    style={{ maxWidth: "100%", display: "inherit" }}
                  />
                </Box>
              )}
              {errors.WorkDocBack && (
                <Typography color="error">{errors.WorkDocBack}</Typography>
              )}
            </FormGroup>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormGroup>
              <FormLabel sx={{ mb: 1 }}>
                What document are you providing
              </FormLabel>
              <Select
                fullWidth
                name="documentType"
                value={formData.documentType}
                label="trade"
                onChange={handleSelect}
                sx={{
                  "&.MuiInputBase-root": {
                    border: "1px solid #ccc",
                    backgroundColor: "#FFF",
                    width: "100%",
                    fontSize: "14px",
                    height: "48px",
                    "@media (max-width: 992px)": {
                      width: "100%",
                    },
                  },
                  fieldset: {
                    display: "none",
                  },
                }}
              >
                <MenuItem value={"studyPermit"}>Study Permit</MenuItem>
                <MenuItem value={"workPermit"}>Work Permit</MenuItem>
                <MenuItem value={"prCard"}>PR Card</MenuItem>
                <MenuItem value={"citizen"}>Citizen (Provincial ID)</MenuItem>
              </Select>
              {errors.documentType && (
                <Typography color="error">{errors.documentType}</Typography>
              )}
            </FormGroup>
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" disabled={isSubmitted}>
          {isSubmitted ? (
            <span style={{ color: "#fff" }}>Submitting...</span>
          ) : (
            "Submit"
          )}
        </Button>
      </form>
    </>
  );
};

export default WorkEligibility;
