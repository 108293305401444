export const getPasswordStrength = (formData) => {
  const { password } = formData;

  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  // const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);

  const isLengthValid = password.length >= 8;

  const strength = {
    upperCase: hasUpperCase,
    lowerCase: hasLowerCase,
    digit: hasDigit,
    specialChar: hasSpecialChar,
    length: isLengthValid,
  };

  return strength;
};

export const checkPasswordValid = (formData) => {
  const strength = getPasswordStrength(formData);
  const formValid =
    strength.upperCase &&
    strength.lowerCase &&
    strength.digit &&
    strength.specialChar &&
    strength.length;
  return formValid;
};
