// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button() {
  const containedStyle = {
    "&.MuiButton-contained": {
      backgroundColor: "#11b4f5",
      color: "#FFFFFF",
      borderRadius: "30px",
      boxShadow: "none",
      textTransform: "uppercase",
      fontSize: "16px",
      fontWeight: "700",

      "&:hover": {
        background: "#11b4f5",
        color: "#fff",
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: "#0000001f !important",
        color: "#00000042 !important",
      },
    },
  };

  const outlinedStyle = {
    "&.MuiButton-outlined": {
      borderRadius: "30px",
      boxShadow: "none",

      border: "1px solid #78c400",

      background: "#78c400",
      color: "#fff",

      textTransform: "capitalize",

      "&:hover": {
        background: "#11b4f5",
        color: "#fff",
        borderColor: "#11b4f5",
      },
      "&.Mui-disabled": {
        backgroundColor: "transparent",
        border: "1px solid #BDC5BE !important",
        color: "#BDC5BE !important",
      },
    },
  };

  // const textStyle = {};

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: false,
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: "16px",
          fontFamily: "'Poppins', sans-serif",

          borderRadius: "8px",
        },
        contained: {
          ...containedStyle,
        },
        outlined: {
          ...outlinedStyle,
        },
        // text: {
        //   ...textStyle,
        // },
      },
    },
  };
}
