import React from "react";

export default function Input(props: any) {
  return (
    <input
      id={props.id}
      className="form-control"
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      readOnly={props.readOnly}
    />
  );
}
