import { Stack } from "@mui/system";
import { Button, Typography, Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListingTable from "../../../component/ListingTable";
import { useEffect, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import Services from "../../../../../api/services";
import Swal from "sweetalert2";
import AddBanner from "./AddBanner";

interface Column {
  id: "sliderName" | "status" | "action";
  label: string;
  minWidth?: number;
}

const columns: readonly Column[] = [
  { id: "sliderName", label: "Name", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 170 },
  { id: "action", label: "Action", minWidth: 170 },
];

interface Data {
  id: number;
  sliderName: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

function createData(
  id: number,
  sliderName: string,
  status: React.ReactNode,
  action: React.ReactNode
): Data {
  return { id, sliderName, status, action };
}

const Banner = () => {
  const [bannerData, setBannerData] = useState([{}]);
  const [addBanner, setAddBanner] = useState(false);
  const [editBanner, setEditBanner] = useState(false);

  const handleAddBanner = () => {
    setBannerData([{}]);
    setAddBanner(true);
  };

  const handleListBanner = async () => {
    await getBanners();
    setAddBanner(false);
    setEditBanner(false);
  };

  const getBanners = async () => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await Services.admin.getBannerService(options);
        setBannerData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBanners();
  }, []);

  const handleEditBanner = async (id: number) => {
    try {
      const tokenString = localStorage.getItem("access_token");
      if (tokenString !== null) {
        const token = JSON.parse(tokenString);
        const options = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await Services.admin.viewBannerService(id, options);
        if (response.status === 200) {
          const data = response?.data?.data;
          setBannerData(data);
        }
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.errors) {
        Swal.fire({
          title: "Error!",
          text: `${error.response.data.errors}`,
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: "An unexpected error occurred.",
          icon: "error",
          confirmButtonColor: "#11b4f5",
        });
      }
      console.error("Error during POST request:", error);
    }

    setAddBanner(true);
    setEditBanner(true);
  };

  const handleRemoveBanner = (id: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this banner!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      confirmButtonColor: "#11b4f5",
      cancelButtonColor: "#ef5350",
      customClass: {
        cancelButton: "swal-cancel-button-class",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const tokenString = localStorage.getItem("access_token");
          if (tokenString !== null) {
            const token = JSON.parse(tokenString);
            const options = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };

            const response = await Services.admin.deleteBannerService(
              id,
              options
            );
            if (response.status === 200) {
              getBanners();
              Swal.fire(
                "Deleted!",
                "Banner image has been deleted.",
                "success"
              );
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Banner image is safe :)", "error");
      }
    });
  };

  const rows = bannerData.map((item: any) => {
    const rowData = {
      id: item.id,
      sliderName: item.image,
      status: item.status,
    };

    return createData(
      rowData.id,
      rowData.sliderName,
      <Box
        component="span"
        sx={{
          color: rowData.status === "1" ? "#11b4f5" : "#FF0000",
        }}
      >
        {rowData.status === "1" ? "Active" : "Deactive"}
      </Box>,

      <Stack direction={"row"} spacing={1}>
        <Button
          variant="contained"
          onClick={() => handleEditBanner(item.id)}
          size="small"
        >
          Edit
        </Button>
        <Button
          onClick={() => handleRemoveBanner(item.id)}
          sx={{
            textDecoration: "none",
            background: "#ef5350",
            color: "#fff",
            borderRadius: "30px",
            textTransform: "uppercase",
            padding: "6px 16px",
            "&:hover": {
              background: "#ef5350",
              color: "#fff",
            },
          }}
        >
          Remove
        </Button>
      </Stack>
    );
  });

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent="space-between"
        display="flex"
        marginBottom={2}
        alignItems={"center"}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: "24px",
            mb: 1,
            "@media(max-width: 768px)": {
              fontSize: "18px",
            },
          }}
        >
          {addBanner
            ? editBanner
              ? "Edit Banner"
              : "Add Banner"
            : "Banner List"}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            mb: 1,
            alignItems: "center",
            display: "flex",
          }}
        >
          {addBanner ? (
            <Button variant="contained" onClick={handleListBanner}>
              <ListIcon sx={{ marginRight: "5px" }} />
              List banner
            </Button>
          ) : (
            <Button variant="contained" onClick={handleAddBanner}>
              <AddCircleOutlineIcon sx={{ marginRight: "5px" }} />
              Add a banner
            </Button>
          )}
        </Typography>
      </Stack>
      {addBanner ? (
        <AddBanner
          setAddBanner={handleListBanner}
          setBannerData={setBannerData}
          bannerData={bannerData}
          editBanner={editBanner}
        />
      ) : (
        <ListingTable
          columns={columns}
          rows={rows}
          shouldShowPagination={true}
        />
      )}
    </>
  );
};

export default Banner;
