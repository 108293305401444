import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
} from "@mui/material";
import Input from "../../../component/input";
import { Stack } from "@mui/system";
import ForgotPassword from "./ForgotPassword";
import { useState } from "react";
import RenderCircle from "../../../component/RenderCircle";
import {
  checkPasswordValid,
  getPasswordStrength,
} from "../../../utils/validation";
import Services from "../../../api/services";
import Swal from "sweetalert2";

interface changePasswordProps {
  title: string;
  shouldShowForgotButton?: boolean;
  userEmail: string;
  handleClose?: (value: boolean) => void;
}

interface formChangePasswordProps {
  email: string;
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const ChangePassword: React.FC<changePasswordProps> = ({
  title,
  shouldShowForgotButton = true,
  userEmail,
  handleClose,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [ShouldShowMessages, setShouldShowMessages] = useState(false);
  const [errors, setErrors] = useState<Partial<formChangePasswordProps>>({});
  const [formData, setFormData] = useState<formChangePasswordProps>({
    email: userEmail,
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const validateForm = () => {
    const newErrors: Partial<formChangePasswordProps> = {};
    // Validate email (required)
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    // Validate currentPassword (required)
    if (!formData.currentPassword.trim()) {
      newErrors.currentPassword = "Current password is required";
    }

    // Validate password (required, minimum length 6)
    if (!formData.password.trim()) {
      newErrors.password = "New password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "New password must be at least 6 characters long";
    }

    // Validate confirm password (required, must match password)
    if (!formData.confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm password is required";
    } else if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

    if (name === "password" && value.length > 0) {
      setShouldShowMessages(true);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = await checkPasswordValid(formData);
    if (validateForm() && isValid) {
      setIsSubmitted(true);
      const data = {
        old_password: formData.currentPassword,
        new_password: formData.password,
      };

      try {
        const tokenString = localStorage.getItem("access_token");
        if (tokenString !== null) {
          const token = JSON.parse(tokenString);
          const options = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await Services.auth.changePasswordService(
            data,
            options
          );
          if (response.status === 200) {
            Swal.fire({
              title: "Thank You!",
              text: "Password Reset successfully",
              icon: "success",
              confirmButtonColor: "#11b4f5",
            }).then((result) => {
              if (result.isConfirmed) {
                setFormData({
                  email: "",
                  currentPassword: "",
                  password: "",
                  confirmPassword: "",
                });
              }
            });
          }

          if (handleClose) {
            handleClose(false);
          }
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Error!",
            text: `${error.response.data.errors}`,
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonColor: "#11b4f5",
          });
        }
        console.error("Error during POST request:", error);
      } finally {
        setIsSubmitted(false);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  const handleForgotPassword = () => {
    setForgotPassword(!forgotPassword);
  };

  return (
    <>
      {forgotPassword ? (
        <ForgotPassword title="Forgot Password" />
      ) : (
        <Box sx={{ mt: 1 }}>
          <Typography variant="h4" sx={{ mb: 3, fontSize: "24px" }}>
            {title}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} lg={6}>
                <FormGroup>
                  <FormLabel sx={{ mb: 1 }}>Email Address</FormLabel>
                  <Input
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    value={formData.email}
                    readOnly={true}
                  />
                  {errors.email && (
                    <Typography color="error">{errors.email}</Typography>
                  )}
                </FormGroup>
              </Grid> */}
              {/* <Input type="hidden" name="email" value={formData.email} /> */}
              <Grid item xs={12} lg={6}>
                <FormGroup>
                  <FormLabel sx={{ mb: 1 }}>Current Password</FormLabel>
                  <Input
                    name="currentPassword"
                    placeholder="Current Password"
                    type="password"
                    value={formData.currentPassword}
                    onChange={handleInputChange}
                  />
                  {errors.currentPassword && (
                    <Typography color="error">
                      {errors.currentPassword}
                    </Typography>
                  )}
                </FormGroup>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormGroup>
                  <FormLabel sx={{ mb: 1 }}>New Password</FormLabel>
                  <Input
                    name="password"
                    placeholder="New Password"
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  {errors.password && (
                    <Typography color="error">{errors.password}</Typography>
                  )}
                  {ShouldShowMessages && (
                    <Box>
                      <Typography color="text.primary">
                        {RenderCircle(getPasswordStrength(formData).upperCase)}
                        At least one uppercase letter
                      </Typography>
                      <Typography color="text.primary">
                        {RenderCircle(getPasswordStrength(formData).lowerCase)}
                        At least one lowercase letter
                      </Typography>
                      <Typography color="text.primary">
                        {RenderCircle(getPasswordStrength(formData).digit)}
                        At least one digit
                      </Typography>
                      <Typography color="text.primary">
                        {RenderCircle(
                          getPasswordStrength(formData).specialChar
                        )}
                        At least one special character
                      </Typography>
                      <Typography color="text.primary">
                        {RenderCircle(getPasswordStrength(formData).length)}
                        At least 8 characters long
                      </Typography>
                    </Box>
                  )}
                </FormGroup>
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormGroup>
                  <FormLabel sx={{ mb: 1 }}>Confirm Password</FormLabel>
                  <Input
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  {errors.confirmPassword && (
                    <Typography color="error">
                      {errors.confirmPassword}
                    </Typography>
                  )}
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitted}
                    sx={{
                      "@media(max-width:768px)": {
                        width: "100%",
                      },
                    }}
                  >
                    {isSubmitted ? (
                      <span style={{ color: "#fff" }}>Submitting...</span>
                    ) : (
                      "Update"
                    )}
                  </Button>
                  {shouldShowForgotButton && (
                    <Button
                      variant="contained"
                      onClick={handleForgotPassword}
                      sx={{
                        "@media(max-width:768px)": {
                          width: "100%",
                        },
                      }}
                    >
                      Forgot Password
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </>
  );
};
export default ChangePassword;
